import { initSCG, initWsgenv } from '@didi/wsgsdk'
// import { omega } from '../omega.js'
import wsgConfig from './wsgConfig.js'
class WsgChallenge {
  constructor(options) {
    const { appId, env, uid, domainList } = options
    this.appId = appId
    this.env = env
    this.uid = uid
    this.domainList = domainList

    this.initWsgSDK()
  }

  initWsgSDK() {
    try {
      // 设备指纹
      initWsgenv({
        appId: this.appId,
        env: this.env,
        uid: this.uid,
      })
      // 挑战应答
      initSCG({
        domainList: this.domainList,
      })
    } catch {}
  }
}

let wsgChallenge = new WsgChallenge(wsgConfig.online)

export default wsgChallenge
