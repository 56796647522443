import { render, staticRenderFns } from "./cate.vue?vue&type=template&id=8a74e166&scoped=true"
import script from "./cate.vue?vue&type=script&lang=js"
export * from "./cate.vue?vue&type=script&lang=js"
import style0 from "./cate.vue?vue&type=style&index=0&id=8a74e166&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8a74e166",
  null
  
)

export default component.exports