// 菜品活动类型配置
export const CATE_ACT_TYPE_CONFIG = {
  SPECIAL: {
    // 特价
    type: 2,
    dataName: 'special', // 活动类型对应activityInfo数据字段匹配
  },
  BUY_GIFT: {
    // 买赠
    type: 4,
    dataName: 'buyGift',
  },
  EFO: {
    // 新人特惠
    type: 100,
    dataName: 'platSpecial',
  },
  OUO: {
    // 老客特惠
    type: 101,
    dataName: 'platSpecial',
  },
}

// 区分商家类型
export const BUSINESS_TYPE = {
  grocery: 3,
}

// 区分来源
export const SCENE = {
  SHOP: 1, // 商家页直接加购
  CATEGORY: 3, // 商家二级页直接加购
  SEARCH: 9, // 店内搜索
  openSku: 2, // 商家页打开规格加购
  shopBill: 4, // 商家页直接提单
  openCartBill: 5, // 商家页打开mini购物车提单
  cateBill: 6, // 商家二级页直接提单
}

// 区分换货来源
export const EXCHANFE_SCENE = {
  SHOP: '1', // 直接加购
  SKU: '2', // 商家页打开规格加购
  SHOPCART: '3', // 规格页（单店购物车）
  CARTSKU: '4', // 单店购物车单sku场景
}

/**
 * 购物车默认最大购买数量（只用来做默认的限制比如props default值）
 * 每个店铺具体的限制在 src/store/modules/common.js cartLimitNum
 */
export const ConstCartLimitNum = 30
