<template>
  <div class="check-item" :class="{ disabled: disabledCard }" @click="itemClick(data)">
    <!-- :class="{ 'disable': getDisableStatus(list, data) && type !== CONSTANTS.SELECT_TYPE.COUNTER }" -->
    <div class="check-item-content">
      <div class="now-level-box">
        <p class="left-desc-title">
          {{ data.itemName }}
          <span v-if="next" class="has-next-level">{{ $t('可定制') }}</span>
        </p>
        <div class="right-price-copies">
          <p v-if="data.price" class="price">{{ '+' + data.priceDisplay }}</p>
          <p v-show="data.price && copies > 1" class="three-copies">{{ $t('每份') }}</p>
        </div>
      </div>
      <div
        v-for="(nextSel, index) in data[CONSTANTS.DISPLAY_TAG.ARRAY_KEY]"
        v-show="checked"
        :key="'nextSel' + index"
        class="next-options-show"
      >
        <p class="desc">
          {{
            formatDisplayName(
              nextSel[CONSTANTS.DISPLAY_TAG.NAME],
              nextSel[CONSTANTS.DISPLAY_TAG.NUMBER],
            )
          }}
        </p>
        <p class="price">
          {{
            '+' +
            currency.numberToCurrencyDynamic(
              sumDisplayPrice(
                nextSel[CONSTANTS.DISPLAY_TAG.PRICE],
                nextSel[CONSTANTS.DISPLAY_TAG.NUMBER],
              ),
              data.currency,
            )
          }}
        </p>
      </div>
    </div>
    <div class="sel-box">
      <!-- 获取是否有下一层级，有&&没加购 使用箭头 -->
      <div v-if="isNextRight"><i class="icon-v3_small_outlinedl_entrance" /></div>
      <div v-else>
        <RideoBtn
          v-if="type === CONSTANTS.SELECT_TYPE.RADIO"
          :is-checked="checked"
          :disabled="disabledCard"
        />
        <CheckBoxBtn
          v-else-if="type === CONSTANTS.SELECT_TYPE.CHECKBOX"
          :is-checked="checked"
          :disabled="disabledCard"
        />
        <Stepper
          v-else-if="type === CONSTANTS.SELECT_TYPE.COUNTER"
          :copies="copies"
          :disabled="disabledCard"
          @add="counterAdd(data)"
          @remove="counterRemove(data)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import CONSTANTS from '@/components/Tree/constants'
import Stepper from './stepper.vue'
import currency from '@/utils/currency'
import RideoBtn from '@/components/RideoBtn'
import CheckBoxBtn from '@/components/CheckBoxBtn'
import strategy from '@/components/Tree/strategy'

export default {
  name: 'OptionsCom',
  components: {
    Stepper,
    RideoBtn,
    CheckBoxBtn,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    data: Object,
    // eslint-disable-next-line vue/require-default-prop
    type: String,
    disabled: Boolean,
    next: Boolean,
    // eslint-disable-next-line vue/require-default-prop
    copies: Number,
    checked: Boolean,
    disableStatus: Boolean,
    counterDisableStatus: Boolean,
    // eslint-disable-next-line vue/require-default-prop
    formatDisplayName: Function,
    // eslint-disable-next-line vue/require-default-prop
    sumDisplayPrice: Function,
  },
  data() {
    return {
      CONSTANTS,
      currency,
      checkList: '',
      rideo: false,
    }
  },
  computed: {
    disabledCard() {
      if (
        this.type === CONSTANTS.SELECT_TYPE.RADIO ||
        this.type === CONSTANTS.SELECT_TYPE.CHECKBOX
      ) {
        return this.disabled || this.disableStatus
      } else if (this.type === CONSTANTS.SELECT_TYPE.COUNTER) {
        return this.disabled || this.counterDisableStatus
      } else {
        return false
      }
    },
    // 获取是否有下一层级，有&&没加购 使用箭头
    isNextRight() {
      return (
        this.next && !this.copies && !(!strategy.isLeaf(this.data) && strategy.match(this.data))
      )
    },
  },
  methods: {
    counterAdd(data) {
      this.$emit('counter-add', data)
    },
    counterRemove(data) {
      this.$emit('counter-remove', data)
    },
    itemClick(data) {
      if (!this.disabled && this.disabledCard) {
        // 需要高亮 原因 并定位
        this.$emit('disabled-item-click')
        return
      }
      this.$emit('item-click', data)
    },
  },
}
</script>
<style lang="scss" scoped>
.check-item {
  display: flex;
  padding: 22px 0;
}
.check-item.disabled {
  // .left-desc-title,
  // .has-next-level,
  // .right-price-copies .price,
  // .right-price-copies .three-copies,
  // .next-options-show .price {
  //   color: #999;
  // }
}
.check-item:last-child {
  border-bottom: none;
}
.check-item-content {
  flex: 1;
}
.now-level-box {
  width: 100%;
  overflow: hidden;
}
.left-desc-title {
  float: left;
  width: 230px;
  line-height: 20px;
  font-family: 'Aspira Medium';
  font-weight: 200;
  font-size: 16px;
  color: #000000;
}
.has-next-level {
  display: inline-block;
  margin-left: 5px;
  padding: 0 4px;
  line-height: 16px;
  font-family: 'Aspira Regular';
  font-weight: 200;
  font-size: 12px;
  color: #666666;
  border: 1px solid #dddddd;
  border-radius: 2px;
}
.right-price-copies {
  float: right;
  text-align: right;
}
.right-price-copies .price {
  height: 20px;
  line-height: 20px;
  font-family: 'Aspira Regular';
  font-weight: 200;
  font-size: 16px;
  color: #000000;
}
.right-price-copies .three-copies {
  line-height: 14px;
  font-family: 'Aspira Regular';
  font-weight: 200;
  font-size: 14px;
  color: #999999;
}
// next
.next-options-show {
  margin-top: 8px;
  overflow: hidden;
}
.next-options-show .desc {
  float: left;
  width: 210px;
  line-height: 16px;
  font-family: 'Aspira Regular';
  font-weight: 200;
  font-size: 14px;
  color: #999999;
}
.next-options-show .price {
  float: right;
  line-height: 16px;
  text-align: right;
  font-family: 'Aspira Regular';
  font-weight: 200;
  font-size: 14px;
  color: #666666;
}
// sel
.sel-box {
  margin-left: 8px;
}

.rideo-btn {
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid #cccccc;
}
.rideo-btn:after {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: #fff;
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.15s ease-in;
}
.rideo-btn.is-checked {
  border: none;
  @include themeBackgroundColor();
}
.rideo-btn.is-checked:after {
  transform: translate(-50%, -50%) scale(1);
}
.rideo-btn.disabled,
.checkbox-btn.disabled {
  opacity: 0.5;
}
.checkbox-btn {
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  border-radius: 4px;
  border: 1px solid #cccccc;
  transition: background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
}
.checkbox-btn:after {
  position: absolute;
  top: 4px;
  left: 8px;
  box-sizing: content-box;
  height: 8px;
  width: 4px;
  content: '';
  border: 2px solid #fff;
  border-left: 0;
  border-top: 0;
  transform: rotate(45deg) scaleY(0);
  transition: transform 0.15s ease-in 0.05s;
  transform-origin: center;
}
.checkbox-btn.is-checked {
  border: none;
  @include themeBackgroundColor();
}
.checkbox-btn.is-checked:after {
  transform: rotate(45deg) scaleY(1);
}
@media screen and (max-width: 768px) {
  .check-item {
    padding: rem(44) 0;
  }
  .left-desc-title {
    width: rem(300);
    line-height: rem(36);
    font-size: rem(36);
  }
  .has-next-level {
    padding: 0 rem(8);
    font-size: rem(24);
    border-radius: rem(4);
  }
  .right-price-copies .price {
    height: rem(36);
    line-height: rem(36);
    font-size: rem(32);
  }
  .right-price-copies .three-copies {
    line-height: rem(28);
    font-size: rem(28);
  }
  // next
  .next-options-show {
    margin-top: rem(20);
    overflow: hidden;
  }
  .next-options-show .desc {
    width: rem(300);
    line-height: rem(34);
    font-size: rem(28);
  }
  .next-options-show .price {
    line-height: rem(34);
    font-size: rem(28);
  }
  // sel
  .sel-box {
    margin-left: rem(24);
  }
  .rideo-btn {
    width: rem(40);
    height: rem(40);
  }
  .rideo-btn:after {
    width: rem(12);
    height: rem(12);
  }
  .checkbox-btn {
    width: rem(40);
    height: rem(40);
    border-radius: rem(4);
  }
  .checkbox-btn:after {
    top: rem(6);
    left: rem(14);
    box-sizing: content-box;
    height: rem(16);
    width: rem(8);
    content: '';
    border: rem(4) solid #fff;
    border-left: 0;
    border-top: 0;
  }
}
</style>
