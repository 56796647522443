import i18n from '@/i18n/locale'

export function getItemById(arr, mduId) {
  let item = null
  arr.forEach(_ => {
    if (_.mduId === mduId) item = _
  })
  return item
}

export function getItemsAmount(arr) {
  return arr.map(_ => {
    return {
      mduId: _.mduId,
      amount: _.amount,
    }
  })
}

export function moduleIdByItemId(arr, itemId) {
  let moduleId = ''
  arr.forEach(item => {
    if (item.itemId === itemId) {
      moduleId = item.mduId
    }
  })
  return moduleId
}

export function temp(cb) {
  cb()
}

export function transRevision(revision) {
  if (revision === undefined) return revision
  return Math.floor(Number(revision) / 100)
}

// 限购 M 库存 N 商品限购 Limit 购物车限购或者别的（如：已加购3，传12）
export function getItemLimitMap(M, N, { cartLimitNum }) {
  return {
    M: {
      name: '库存',
      prop: 'stock',
      msg: i18n.t('FoodC_shop__hSnf', { num: M }),
    },
    N: {
      name: '商品限购',
      prop: 'maxSale',
      msg: i18n.t('FoodC_shop__MWyO', { num: N }),
    },
    Limit: {
      name: '购物车限购',
      prop: 'Limit',
      msg: i18n.t('Groceries_shop2_Fwyu_yymq', { num: cartLimitNum }),
    },
  }
}

export function getMsgForCheckLimit(M, N, Limit, type, payload) {
  const itemLimitMap = getItemLimitMap(M, N, payload)
  if (type) {
    return {
      limitType: itemLimitMap[type].prop,
      limitMsg: itemLimitMap[type].msg,
    }
  }
  const bol = M <= N
  let limitStatus = bol ? 'M' : 'N'
  let limitAmount = bol ? M : N
  if (limitAmount > Limit) {
    limitStatus = 'Limit'
    limitAmount = Limit
  }
  return {
    limitType: itemLimitMap[limitStatus].prop,
    limitAmount,
    limitMsg: itemLimitMap[limitStatus].msg,
  }
}
// 根据id 找到商品的数据
export function findItemFromId(id, list) {
  list.forEach((classData, classIdx) => {
    const itemList = classData.items
    itemList.forEach((item, itemIdx) => {
      if (item.itemId === id) {
        return {
          classIdx,
          itemIdx,
          item,
        }
      }
    })
  })
  return null
}
/**
 * 处理请求加购时的入参 因为debounce
 * 根据 mduId 去重
 * 获取列表最后的 同 mduId 数据
 * 只返回 mduId amount buyType replacementType preselectionItemId
 */
export function getHasDebounceParams(paramsList) {
  const map = {}
  paramsList.forEach(item => {
    map[item.mduId] = item
  })
  const keys = Object.keys(map)
  return keys.map(key => {
    const { mduId, amount, buyType = 0, replacementType, preselectionItemId } = map[key]
    return {
      mduId,
      amount,
      buyType,
      replacementType,
      preselectionItemId,
    }
  })
}

/**
 * 处理请求加购时的入参 因为debounce
 * 根据 itemId 去重
 * 获取列表最后的 同 itemId 数据
 */
export function getItemOnlyParams(paramsList, keyName = 'itemId') {
  const map = {}
  paramsList.forEach(item => {
    map[item[keyName]] = item
  })
  const keys = Object.keys(map)
  return keys.map(key => {
    return map[key]
  })
}

/**
 * 商品规格描述信息、价格、优惠价字段组装处理
 */
export function formatItemData(itemData, buyType = 0) {
  let {
    buyDesc = '',
    curBuyType = 0,
    buyTypeInfoList = [],
    price,
    priceDisplay = '',
    specialPrice,
    specialPriceDisplay = '',
  } = itemData
  curBuyType = buyType || curBuyType
  if (curBuyType && buyTypeInfoList?.length) {
    // 按个/重量售卖
    const curTypeInfo = buyTypeInfoList.find(item => item?.buyType === curBuyType)
    const {
      unit = '',
      buyDesc = '',
      price,
      priceDisplay = '',
      specialPrice,
      specialPriceDisplay = '',
    } = curTypeInfo
    return {
      unit,
      buyDesc,
      price,
      priceDisplay,
      specialPrice,
      specialPriceDisplay,
    }
  } else {
    // 按份售卖
    return {
      unit: '',
      buyDesc,
      price,
      priceDisplay,
      specialPrice,
      specialPriceDisplay,
    }
  }
}
