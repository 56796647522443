<template>
  <div class="cate-add-wrapper" :class="{ disabled: disabled, 'no-slot': !$slots.default }">
    <slot></slot>
    <div class="add-btn" @click="add">
      {{ text || $t('添加') }}
      <div v-if="nowPrice !== undefined" class="price-wrapper">
        <div class="price-center">
          <p
            class="price-act"
            v-text="currency.numberToCurrencyDynamic(nowPrice, cateCurrency)"
          ></p>
          <p
            v-if="originPrice && nowPrice !== originPrice"
            class="price-ori"
            v-text="currency.numberToCurrencyDynamic(originPrice, cateCurrency)"
          ></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import currency from '@/utils/currency'

export default {
  name: 'CateAdd',
  props: {
    // eslint-disable-next-line vue/require-default-prop
    disabled: [Boolean, Number],
    text: {
      type: String,
      default: '',
    },
    // eslint-disable-next-line vue/require-default-prop
    nowPrice: [String, Number],
    // eslint-disable-next-line vue/require-default-prop
    originPrice: String,
    // eslint-disable-next-line vue/require-default-prop
    cateCurrency: String,
  },

  data() {
    return {
      currency,
    }
  },
  mounted() {},
  methods: {
    add() {
      // if (this.disabled) return
      this.$emit('add')
    },
  },
}
</script>

<style lang="scss" scoped>
.cate-add-wrapper {
  overflow: hidden;
}
.no-slot .add-btn {
  width: 100%;
}
.add-btn {
  position: relative;
  float: right;
  width: 270px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  font-family: DiDiSans-Pro-Bold;
  font-weight: 500;
  font-size: 20px;
  color: #ffffff;
  background-color: #ff7a45;
  background-image: linear-gradient(270deg, #ff7a45 39%, #ff6325 99%);
  border-radius: 4px;
  cursor: pointer;
}

.price-wrapper {
  position: absolute;
  right: 12px;
  top: 0;
  height: 48px;
  display: table;
  text-align: right;
}
.price-wrapper .price-center {
  display: table-cell;
  vertical-align: middle;
}
.price-act {
  line-height: 1;
  font-family: DiDiSans-Pro-Bold;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
}
.price-ori {
  margin-top: 4px;
  line-height: 1;
  font-family: 'Aspira Light';
  font-weight: 200;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: normal;
  text-decoration: line-through;
}

.cate-add-wrapper.disabled {
  .add-btn {
    background: #f5f5f7;
    color: #e3e3e6;
    .price-act {
      color: #e3e3e6;
    }
    .price-ori {
      color: #e3e3e6;
    }
  }
}
@media screen and (max-width: 768px) {
  .add-btn {
    width: rem(390);
    height: rem(104);
    line-height: rem(104);
    font-family: DiDiSans-Pro-Bold;
    padding: 0 rem(32);
    font-weight: 500;
    font-size: rem(36);
    text-align: left;
    background-image: none;
    background-color: var(--color-6);
    border-radius: rem(32);
  }
  .price-wrapper {
    right: rem(32);
    height: rem(104);
  }
  .price-act {
    font-size: rem(30);
  }
  .price-ori {
    margin-top: rem(2);
    font-size: rem(24);
    font-family: DiDiSans-Pro-Medium;
    color: #ffc9b0;
  }
}
</style>
