module.exports = {
  "FoodC_edit_stZr_IBeA": "Otro",
  "FoodC_check_HTib_sWqA": "Realizando pedido",
  "FoodC_202409_dTTH_bjFQ": "Este pedido se ha completado o no existe, por lo que no puede verse.",
  "FoodC_edit_AxtX_PAiY": "Ejemplo: 1118, edificio C",
  "FoodC_202412_PwIG_QPFY": "Ingresa una dirección",
  "FoodB_Overview_AasD_LvfW": "Ver más",
  "FoodC_edit_miEX_KTUj": "Piso/Habitación",
  "CS_page_Courier_Phone_ghUr": "Teléfono del socio repartidor",
  "FoodC_Website_At_present_XpFw": "En este momento no se puede canjear en la página web. Por favor, canjea el código promocional en la aplicación.",
  "FoodC_20240607_eLRx_hJRj": "Página no disponible en computadoras",
  "FoodC_edit_rpfC_Jyhp": "Edificio / Piso / Unidad / Núm. de habitación",
  "FoodC_202408_wten_GUVt": "Selecciona la dirección de entrega",
  "FoodC_edit_Hxmm_vrkU": "Edificio residencial",
  "FoodC_202408_PJcq_SjqG": "¡Ups! No hay información de contacto para esta tienda",
  "FoodC_001_The_WEB_Iizt": "La versión web no acepta actualmente el uso de PayPay. Para usar PayPay, descarga {{download}}",
  "FoodC_page_Upon_discovery_baTq": "El cupón no puede cambiarse ni canjearse por efectivo. El incumplimiento de los términos y condiciones del cupón puede dar lugar a la recuperación del monto relevante y la suspensión de la cuenta, y puede haber sanciones de acuerdo a la ley",
  "FoodC_homepage_Enter_delivery_IZAu": "Ingresar dirección de entrega",
  "FoodC_edit_bZFb_ghVG": "Encontrarse fuera del edificio del hotel",
  "FoodC_edit_qhyi_ihvA": "Ejemplo: Hotel Y, Parque El Virrey, Cra. 15 #88-10, Chapinero, Bogotá, Cundinamarca, Colombia",
  "FoodC_Card2_When_the_sFOa": "Abre a las",
  "soda-c-i18n---早间：%s-%s": "Durante la mañana: %s a %s",
  "FoodB_202303_YWUL_hwNF": "Aceptar",
  "soda-c-i18n---延迟送达%s分钟以上": "Demora mayor a %s minutos",
  "FoodC_stores_All_stores_clRv": "Todos los restaurantes",
  "FoodC_edit_SQwr_AVSp": "Dir. y nombre de apto.",
  "FoodC_edit_OVRs_CzUt": "Hotel",
  "FoodC_dish_XX_YY_opCV": "Termina en",
  "FoodC_edit_SKZZ_WpVu": "Tipo de edificio",
  "FoodC_202408_HkLu_Mwgv": "No hay permisos de ubicación",
  "soda-c-i18n---个人中心的“优惠券”": " Mi Perfil > Cupones.",
  "FoodC_202408_QAer_OYaB": "¡Te damos la bienvenida!",
  "FoodC_202408_leda_oOpl": "Mejor control de pedidos",
  "soda-c-i18n---1、超时赔付服务是%s为您免费提供的服务，当前订单（不含已取消订单）未在预计送达时间内送达，%s将赔付给您一定金额的优惠券，订单完成后可以在中查看。": "1. Como parte de nuestro programa de lealtad queremos darte unos cupones en caso de una entrega con demora. Si un pedido no se entrega en el tiempo de entrega estimado (salvo los pedidos cancelados), %s te dará uno o más cupones gratis. Puedes ver los cupones una vez que se complete el pedido en",
  "FoodC_homepage_Search_for_CiMZ": "Buscar comida",
  "FoodC_edit_SkdG_WQKu": "Encontrarse en la recepción del hotel",
  "FoodC_20240607_wfRH_rTAa": "Información de la tienda",
  "FoodD_suspensionpage_In_the_zGEU": "No hay más resultados",
  "FoodC_202408_AFKG_hWIj": "Fuera de rango. Favor de cambiar la dirección",
  "FoodB_reason_Contact_Courier_Fqog": "Contactar",
  "FoodC_202412_nXPk_kwtQ": "¿No encuentras la dirección? Prueba otras palabras",
  "FoodC_edit_QrTY_mpUt": "Ejemplo: cl. 19 #63-22, barrio Baquero, Barrios Unidos, Bogotá, Cundinamarca, Colombia",
  "Entrega_order__YnnH": "DiDi Entrega Business",
  "FoodC_education_1_Customers_Gpbb": "1. Antes de realizar un pedido, elige la mejor opción de entrega para ti. Indica al socio repartidor cómo prefieres la entrega del  pedido según la opción que selecciones:",
  "FoodC_perception_Tip_exceeds_VSFf": "La propina supera el límite",
  "FoodC_202408_reKH_lclj": "Agregar dirección de entrega",
  "FoodC_edit_hDGv_arOT": "Encontrarse en la recepción de abajo",
  "soda-c-i18n---现金": "Efectivo",
  "sailing.d-api.common.页面获取失败，请稍后重试": "No se puede recuperar la página. Vuelve a intentarlo más tarde.",
  "FoodC_education__1_mJTG": "(1) Si la tienda ya confirmó y aceptó el pedido, puedes usar la función \"Cancelar pedido\" o contactar a la tienda y pedirle que cancele el pedido. Si la tienda no acepta cancelar el pedido porque ya está listo o por otro motivo razonable, no podrás cancelar el pedido sin costo extra. Si insistes en cancelar o te niegas a recibir una parte del pedido o el pedido completo, se deberán pagar los gastos correspondientes de ese pedido.",
  "FoodC_20240607_STdK_ZTMu": "Servicio técnico proporcionado por {{brand_name}}",
  "FoodC_education_Principles_of_RKEk": "Recomendaciones en caso de algún inconveniente con la entrega",
  "soda-c-i18n---当前订单（不含已取消订单）未在预计送达时间内送达，%s将赔付给您一定金额的优惠券，订单完成后可以在": "Si un pedido no se entrega en el tiempo de entrega estimado (salvo los pedidos cancelados), %s te dará uno o más cupones para remediar las cosas. Una vez que se complete tu pedido, puedes ver los cupones en ",
  "soda-c-i18n---将赔付给您1张%s优惠券": "Obtienes un cupón de %s.",
  "FoodC_edit_iuIV_BOva": "10 minutos",
  "FoodC_20240607_Olbe_DAqS": "No se puede cargar",
  "FoodC_202408_SXSA_LasS": "Agrega tu número de celular para más ventajas",
  "FoodC_page_Invite_friends_oGuj": "Invita a un amigo a probar {{\t\nproductname_didi_food}}. Si canjea el cupón que le enviaste y completa un pedido sin ningún reembolso dentro de los 14 días de haber recibido tu invitación y antes de que el evento termine, incluso si no usa el cupón en su pedido, recibirás un [cupón] de {{\t\nproductname_didi_food}}",
  "FoodC_202408_GeGr_xwdt": "Comunicación más sencilla",
  "支付失败": "Error al pagar",
  "FoodC_202408_xwYJ_IzdZ": "Continuar",
  "FoodC_202408_ksyj_sXlp": "Acerca de la tienda",
  "FoodC_sidebar_Scan_QR_ONzJ": "Escanea el código QR con la cámara de tu celular",
  "FoodC_sidebar_Use_the_huoZ": "Pide cuando quieras, donde quieras",
  "FoodC_education_3_If_wduh": "",
  "FoodC_MVP_ASpO_MFfF": "Detalles del pedido",
  "FoodC_202412_dCuL_VUFU": "Actual.",
  "FoodC_edit_GoWo_Frbk": "Piso/Unidad/Habitación",
  "FoodC_details_Refund_Details_sGIk": "Detalles del reembolso",
  "FoodC_202412_bolI_YXBT": "Direcciones guardadas",
  "FoodC_page_This_special_fYuo": "Esta promoción sólo se ofrece en áreas de {{\t\nactivity_city}}, donde {{\t\nproductname_didi_food}} esté disponible",
  "FoodC_page_For_any_IWNZ": "Si tienes alguna pregunta sobre esta promoción, contacta a Soporte DiDi en {{contact_email}}",
  "Entrega_name_99_Flash_maAd": "99Entrega",
  "FoodC_20240607_Upph_ldoj": "Recordatorio de compra",
  "支付方式": "Payment Method",
  "FoodC_education_Basic_requirements_hIbv": "Recomendaciones para los usuarios con respecto a la entrega",
  "FoodC_202410_ZHQi_kNsP": "Editar",
  "FoodC_202412_Oant_EESB": "Siguiente",
  "FoodC_edit_JdIz_uKvw": "Encontrarse afuera",
  "FoodC_education_In_order_SRIb": "Esta política de cancelación se creó para ayudar a que todos los usuarios tengan una buena experiencia con {{brand_name}}.",
  "FoodC_1_mnqk_AcOb": "No se pudo copiar",
  "FoodC_202408_qXNe_iBNs": "No se pudo obtener la ubicación actual",
  "FoodC_202408_LBac_RLgP": "Agrega la dirección de entrega para que el socio repartidor pueda entregar el pedido",
  "FoodC_202408_sbmp_YFyl": "Ubicación actual",
  "FoodC_202408_RRtT_ZjIG": "Agregar dirección de entrega",
  "FoodC_20240607_hhNB_mCBD": "Número de teléfono celular",
  "FoodC_presentation_DiDi_Delivery_vrwd": "DiDi Entrega",
  "FoodC_202408_ZOBi_xjfW": "Buscar nombre del edificio/departamento",
  "FoodC_supplement_Modify_Mobile_ZJkT": "Para modificar tu número de teléfono o tu contraseña, agregar una dirección de correo electrónico o eliminar tu cuenta,",
  "FoodC_address_gNYi_HdzX": "No se pudo guardar",
  "FoodC_edit_fLUx_wHAP": "Ejemplo: “llevo una camisa estampada.” También puedes subir fotos de los alrededores para ayudar al socio repartidor a entregar tu pedido en el lugar correcto.",
  "FoodD_problem_I_see_NhGG": "Aceptar",
  "FoodC_202408_BsWi_bWTi": "Editar dirección",
  "FoodC_202408_LtuD_oTxz": "Cancelar",
  "FoodC_sales_If_the_TsPm": "Para ayudarnos a verificar el problema con la tienda y el socio repartidor, por favor dinos si el pedido fue entregado en un empaque resellable o sellado de alguna otra forma (como con grapas).",
  "FoodC_202408_uMeD_ECQU": "Hubo un error. Inténtalo de nuevo.",
  "FoodC_202408_KYqe_yVZD": "Ahora no",
  "FoodC_edit_kzdk_yzVc": "Encontrarse en la puerta de mi habitación",
  "FoodC_Website_The_WEB_pBbW": "¡Lo sentimos! La versión web no admite el pago con {{pay_name}}. Para pagar con {{pay_name}}, por favor, descarga la aplicación.",
  "FoodC_Website_From_shop_Jatv": "Realiza un pedido a [shop name] | DiDi Food",
  "FoodC_edit_Fyzp_tERt": "El usuario repartidor te contactará cuando llegue al punto de entrega y esperará en el lugar acordado por máximo {{time}}. Por favor, ten tu teléfono a la mano.",
  "FoodC_202408_pSzr_CJqz": "Guardar dirección",
  "FoodC_sales_Supplementary_information_ihyk": "Detalles adicionales",
  "FoodC_edit_IhsK_uKOF": "Seleccionar",
  "FoodC_education__2_uVUK": "(2) La tienda a la que le hiciste el pedido estaba cerrada, un producto estaba agotado, ocurrió un accidente o la tienda o el socio repartidor cancelaron el pedido por otro motivo. En ese caso, recibirás un reembolso completo.",
  "FoodC_001_This_operation_Dwog": "Esto eliminará todos los platos del carrito. ¿Deseas continuar?",
  "FoodC_202412_JiED_bPrx": "Sin resultados. Ingresa una calle y número válidos.",
  "FoodC_page_Your_invited_sPpR": "El amigo al que invites debe ser un usuario nuevo de {{\t\nproductname_didi_food}}. Los cupones que son aplicables en la primera orden están limitados a uno por usuario (cada usuario debe tener un número de teléfono, un dispositivo y una cuenta de pago únicos)",
  "FoodC_edit_HEFc_Dppo": "Encontrarse fuera de mi edificio residencial",
  "FoodC_web_Only_1_ZddK": "Sólo 1",
  "FoodC_progress_Order_completed_joIL": "Pedido completado",
  "FoodC_page_Rewards_are_dWeY": "El premio se envía con base en la ciudad donde se encuentre la [persona referida]",
  "FoodC_202412_DMKw_kWMA": "Intenta buscar la calle y número",
  "FoodC_202408_PByw_CSDg": "Ofertas exclusivas",
  "FoodC_1_ScPa_MVPN": "Copiado",
  "FoodC_education_In_the_nIgD": "La cancelación se considerará responsabilidad tuya en las siguientes situaciones:",
  "FoodC_education_Platform_Delivery_MJPQ": "Entrega a través de la plataforma",
  "FoodC_202408_qGSr_IQoW": "Editar",
  "FoodC_202412_SJdc_Bwta": "¿No es una ubicación cercana? Toca el botón de la derecha para actualizar tu ubicación.",
  "FoodC_dish_New_guest_bkGC": "Para primer pedido",
  "FoodC_202408_WiRK_SITI": "Agregar dirección",
  "FoodC_202408_HNlI_lTpJ": "No se ha agregado propina aún",
  "soda-c-i18n---将赔付给您%s张免配送费券": "Obtienes %s cupón para un envío gratis.",
  "FoodC_all_You_haven_lSmt": "Aún no invitas a tus amigos. Intenta enviar una invitación.",
  "FoodC_dish_Look_again_fJrp": "Ahora no",
  "FoodC_202408_IDjR_CbbT": "Aceptar",
  "FoodC_20240607_ffiX_wNQG": "WhatsApp",
  "soda-c-i18n---提交申请": "Solicitar cancelación",
  "FoodC_rule_14_Up_dovx": "El límite es 2 cupones por usuario por día",
  "FoodC_education__2_KHhl": "(2) Si no llegaste al lugar de la entrega para la hora especificada o si ingresaste la dirección o número de teléfono incorrectos, te negaste a aceptar una llamada del socio repartidor o te negaste a recibir parte del pedido o el pedido completo sin un motivo válido, se deberá pagar el pedido.",
  "FoodC_nonaffiliate_Courier_is_iunG": "El socio repartidor va en camino a la tienda para realizar tu pedido.",
  "FoodC_edit_cYHO_lOnH": "Ejemplo: apartamentos XY, torre 1, núm. 88, Barrios Unidos, Bogotá, Cundinamarca, Colombia",
  "FoodC_202408_IBpM_TxlD": "Otro",
  "必选": "No es posible desactivar",
  "FoodC_education_If_you_RTIV": "Si pagaste un pedido y necesitas cancelarlo, se aplicará lo siguiente:",
  "FoodC_education_2_Receiving_gFZl": "2. Revisa que la dirección de entrega y el método de contacto que proporciones sean los correctos.",
  "FoodC_page_The_threshold_yZFq": "Los límites de uso y el periodo de vigencia de los cupones aparecen en el cupón. Los cupones sólo pueden usarse durante el periodo de vigencia indicado",
  "FoodC_education__3_TDsa": "(3) Te negaste a recibir el pedido, ya sea una parte del mismo o por completo",
  "FoodC_202412_QJMa_Qkwg": "Ingresa una nueva dirección de entrega para agregarla",
  "FoodC_education_2_If_SoSk": "2. Si un inconveniente relacionado con una entrega sucede porque el socio repartidor no siguió las recomendaciones, se podría considerar que fue responsabilidad del socio repartidor.",
  "FoodC_202408_cjXm_VVcd": "Si sales, no podrás ver tu pedido",
  "FoodC_education__unk_aOCD": "Recomendaciones de la aplicación sobre el tipo de entrega:  {{delivery_type}}",
  "FoodC_edit_PUvz_vhsD": "Barrio",
  "确认": "Subir",
  "请选择支付方式": "Selecciona un método de pago",
  "soda-c-i18n---订单实际送达时间晚于预计送达时间%s分钟以上，": "Si tu pedido llega %s minutos después del tiempo de entrega estimado,",
  "soda-c-i18n---取消订单": "Cancelar pedido",
  "FoodC_education_3_Customers_Zoec": "3. Para que el socio repartidor pueda contactarte si lo necesita, procura tener tu teléfono a la mano durante la entrega.",
  "FoodC_202409_yzRW_xKLM": "Este pedido está cerrado. Para ver los detalles, ingresa el número de teléfono celular del solicitante.",
  "小费": "Propina voluntaria",
  "FoodC_update_The_expected_ZzFg": "El tiempo de entrega estimado se basa en el tiempo de entrega más reciente.",
  "FoodC_202412_kamX_bNUi": "Por favor, selecciona una dirección para agregar",
  "FoodC_20240607_fIqe_iMtS": "Aceptar",
  "FoodC_202408_BTvL_umes": "¿Deseas salir?",
  "FoodC_edit_TzbS_wBhH": "Ejemplo: Empresa X, Cl. 81 #11-08, Chapinero, Bogotá, Cundinamarca, Colombia",
  "FoodC_education__2_jwSo": "(2) Dejar en la puerta (con nota)",
  "FoodC_202408_ReqK_LVas": "Confirmar",
  "FoodC_web_Application_Progress_UYtG": "Estado de la solicitud",
  "FoodC_edit_NDmM_lEJY": "Encontrarse en la puerta",
  "FoodC_202408_HfXO_xbcw": "Ver",
  "FoodC_dish_Are_you_TaUN": "¿Salir de la página de la promoción?",
  "FoodC_education__1_vwgm": "(1) Entregar en la puerta",
  "FoodC_education_4_Customers_laxR": "4. Una vez que el socio repartidor llegue a tu ubicación, tendrás 10 minutos para recibir tu pedido.",
  "FoodC_edit_GeFS_ZPLW": "Dirección y nombre del edificio",
  "FoodC_202408_cktm_HTVV": "Entendido",
  "FoodC_shop__MWyO": "Límite de compra: {{num}}",
  "FoodC_perception_Tips_hZWk": "Propina",
  "FoodC_202412_aCvV_UNyj": "Agregar nueva dirección",
  "FoodC_education__1_qvVC": "(1) No recibiste el pedido a tiempo",
  "FoodC_method_Wait_keAM": "Esperar",
  "soda-c-i18n---请告诉我们，帮助我们一起改善": "Por favor, envíanos tus comentarios y ayúdanos a mejorar.",
  "FoodC_202408_zoCD_Pszh": "Continuar",
  "FoodC_supplement_Return_wVpv": "Atrás",
  "FoodC_2022__SEQk": "Propina",
  "FoodC_optimization_rmLx_bSmk": "Busca tu dirección de entrega y agrégala",
  "FoodC_edit_bxpb_udFq": "Ejemplo: Edificio C, Núm. 21, Cl. 185 #45-03, Suba, Bogotá, Cundinamarca, Colombia",
  "FoodC_202410_SiDC_Empm": "Pedir",
  "优惠券": "Cupón",
  "FoodC_202410_OFhs_UnlA": "Explorar",
  "FoodC_progress_Order_canceled_KDSH": "Pedido cancelado",
  "FoodC_optimization_RLDn_cLAQ": "Agregar dirección de entrega",
  "FoodC_optimization_hksX_yevn": "Agrega la dirección donde se entregará tu pedido.",
  "FoodC_education_Meal_picking_ziun": "Recomendaciones para la entrega",
  "soda-c-i18n---订单实际送达时间晚于预计送达时间%s分钟以上、%s分钟以下，": "Si tu pedido llega entre %s y %s minutos después del tiempo de entrega estimado, ",
  "FoodC_sales_Condition_of_lnwg": "¿Estaba sellado el empaque? (Obligatorio)",
  "FoodC_20240607_fhRg_kmPn": "Te recordamos que tienes un pedido con pago en efectivo sin completar y no puedes utilizar ese método de pago de nuevo hasta que lo completes.",
  "FoodC_202408_XlDL_LjiM": "Entendido",
  "soda-c-i18n---送您1张%s折扣券；": "Te daremos un cupón del %s de descuento.",
  "productname_didi_food": "DiDi Food",
  "FoodC_revision_Contact_customer_XbFk": "Contáctanos",
  "分钟": "min",
  "FoodC_shop__hSnf": "Queda(n) {{num}}",
  "FoodC_Website_DiDi_FoodDiDi_wtqn": "DiDi Food | DiDi Repartidor. Disfruta tu comida cuando quieras, donde quieras.",
  "FoodC_edit_EqUG_FRvo": "Apto./Piso/Habitación",
  "FoodC_edit_dECN_fkIy": "Encontrarse fuera del edificio de oficinas",
  "FoodC_202408_tJIe_WbFo": "Cancelar",
  "FoodC_202410_mjfG_DDhf": "Agregar",
  "FoodC_edit_XZzK_rlGs": "Ej: apto. C, piso 8, habitación 1118",
  "FoodC_edit_RMpJ_MPyp": "Método de entrega",
  "FoodC_202408_tlOV_uOmJ": "Agrega tu número de celular y obtén promociones en tus pedidos",
  "FoodC_202412_SxTA_sqKU": "Ubicación cercana",
  "FoodC_improvement_Rgol_abYw": "Se envió tu solicitud de cancelación y la tienda la procesará en un plazo de {{XminYs}}. Gracias por esperar.",
  "FoodC_dish_Purchase_NrCl": "Pide ya",
  "FoodC_popup_After_closing_ujoW": "Si sales, no se guardarán los platos seleccionados",
  "FoodC_edit_ldOR_SyHv": "Ejemplo: “llevo una camisa estampada y estoy esperando en la calle.” También puedes subir fotos de los alrededores para ayudar al socio repartidor a entregar tu pedido en el lugar correcto.",
  "FoodC_page__KWIm": "Aceptar",
  "FoodC_remark_Enjoy_your_OvmD": "¡Que disfrutes tu comida!",
  "FoodC_optimization_knQx_VpIr": "Agregar",
  "soda-c-i18n---超时赔付规则说明": "Cupón por entrega demorada",
  "FoodC_education_5_Cash_zBuY": "5. Si realizas un pedido con pago en efectivo, procura tener suficiente dinero a la mano para pagar el pedido antes de aceptar la entrega.",
  "Groceries_shop2_Fwyu_yymq": "Sólo se puede agregar hasta {{num}} artículos al carrito.",
  "FoodC_sidebar_Update_successfully_cuyI": "Actualización completada",
  "FoodC_education__1_IIfh": "(1) Si cancelaste antes de que la tienda haya aceptado el pedido con la función \"Cancelar pedido\" en la sección de Detalles del pedido.",
  "支付": "Pagar",
  "FoodC_reasons_Enter_a_Pnzg": "Por favor, describe los detalles del problema.",
  "FoodC_202408_PvIX_VPCT": "Ingresa para ver ofertas y rastrear tu pedido",
  "FoodC_20240607_RSyh_XJnN": "Por favor, abre el enlace en tu dispositivo móvil",
  "Groceries_1_MhVY_Etfm": "Artículo cambiado",
  "FoodC_Notification_ZawA_BoKt": "Ver pedidos en curso",
  "FoodC_edit_uMEP_ZtVV": "Nombre de la empresa",
  "FoodC_20240607_IbZr_cIyA": "Por favor, revisa el enlace de la tienda y vuelve a intentarlo",
  "sailing.c-api-intl.你最多可以下单{0}的货到付款单": "El monto máximo para pedidos con pago en la entrega puede ser hasta {{0}}",
  "FoodC_202412_Afcx_fMwc": "Seleccionar dirección",
  "FoodC_payment_eCqh_ogxh": "Los pagos en línea aún no están disponibles. Te recomendamos seguir al pendiente.",
  "FoodC_homepage_Now_place_Kmkm": "Entrega de comida hasta tu puerta",
  "FoodC_education__4_QYXm": "(4) Te negaste a pagar el pedido con pago en efectivo",
  "FoodC_202410_Ejdi_makf": "Elige una tienda",
  "FoodC_education_In_the_MKeB": "La cancelación no se considerará responsabilidad tuya en las siguientes situaciones:",
  "FoodC_education__3_ltNM": "(3) Otros factores que no puedas controlar y que causaron la cancelación del pedido, como que no se haya podido encontrar un socio repartidor que entregue el pedido o algún otro asunto.",
  "FoodC_202408_Jvou_Cqor": "Lugares cercanos",
  "FoodC_202412_enwa_nWfu": "Este artículo está agotado. Puedes explorar otros artículos de la tienda.",
  "FoodC_1_dXUH_ThDd": "Ver más",
  "soda-c-i18n---送您1张%s优惠券；": "Te daremos un cupón de %s.",
  "FoodC_edit_fOqC_upyr": "Encontrarse en la recepción",
  "FoodC_202410_UazG_LTwS": "No hay tiendas disponibles",
  "FoodC_perception_Sorry_you_FKSy": "Lo sentimos, sólo puedes agregar hasta 15 artículos en tu carrito",
  "FoodC_202410_KMCG_kJBn": "Cambiar",
  "FoodC_20240607_YPzJ_iyMx": "Artículo sólo para mayores de {{0}} años. La venta y consumo de alcohol se prohíbe para menores de edad.",
  "FoodC_202410_hAya_NcZF": "Confirmar ({{countdown}} s)",
  "FoodC_20240607_wWhy_deWR": "Por favor, actualiza la página y vuelve a intentarlo",
  "FoodC_all_No_reward_ocGJ": "Sin premios",
  "FoodC_education_In_order_AZCw": "Las recomendaciones para la entrega se crearon con el motivo de ayudar a que todos los usuarios tengan una buena experiencia con {{brand_name}}. En caso de que haya algún inconveniente relacionado con la entrega, se aplicará lo siguiente.",
  "FoodC_edit_KDUB_nVRR": "Obligatorio",
  "FoodC_optimization_GUGp_fDxe": "Volver",
  "FoodC_edit_oOAR_FExH": "Oficinas",
  "FoodC_202412_MrwA_fRUi": "En este momento, la tienda está cerrada. Por favor, regresa más tarde.",
  "FoodC_education__2_KZTZ": "(2) El socio repartidor no pudo contactarte",
  "FoodB_Overview_udmx_lJTl": "Error al cargar. Haz clic para volver a intentar.",
  "FoodC_edit_PdIk_ikix": "Nombre del hotel",
  "FoodC_remark_Thank_you_ENoY": "¡Gracias por elegir {{brand_name}}!",
  "FoodC_edit_NLqn_FXYs": "No tengo esta información",
  "soda-c-i18n---联系商家": "Comunícate con la tienda.",
  "FoodC_dish_Leaving_LwWJ": "Salir",
  "FoodC_202408_ajMC_lsta": "Tienda cerrada",
  "FoodC_20240607_qihL_obWb": "No se encontró la tienda",
  "FoodC_education_Cancellation_Policy_fxrl": "Política de cancelación",
  "FoodC_202409_rOOL_Peyp": "Ingresar número de celular",
  "FoodC_edit_wBJW_yfSo": "Apartamento",
  "FoodC_202408_RTrU_hOhN": "Por favor, activa los permisos de ubicación. Para hacerlo, ve a la configuración del sistema, selecciona la aplicación y activa los permisos de ubicación correspondientes.",
  "FoodC_202408_IfNx_VyNu": "Direcciones guardadas",
  "FoodC_dish_Current_activity_Iyrv": "Esta promoción termina en {{arrived_time}}. Las promociones para nuevos usuarios ya no estarán disponibles después de esa fecha",
  "FoodC_dish_You_will_AXAT": "Sólo puedes disfrutar de promociones para nuevos usuarios en esta página.",
  "FoodC_edit_NwrE_OHgM": "Encontrarse fuera del edificio de apartamentos",
  "FoodC_20240607_aOOB_RMKm": "Pago en efectivo no disponible temporalmente",
  "soda-c-i18n---晚间：%s-%s": "Durante la noche: %s a %s",
  "FoodC_edit_TfNW_USPN": "Ej: Habitación 1118, piso 8",
  "FoodC_edit_cKhP_xcTQ": "Encontrarse en la puerta de mi vivienda",
  "FoodC_20240607_hfCf_kdpO": "Teléfono",
  "FoodC_page__iyoe": "Ver menos",
  "FoodC_202410_YFVb_hzJK": "Agrega una dirección",
  "FoodC_202408_YjqN_Yvyz": "Agrega una dirección para ver tarifa y tiempo",
  "FoodC_202410_FxEp_LCxL": "Procesando pago",
  "Groceries_web__Ovnh": "¡Lo sentimos! Por el momento sólo se puede acceder a las tiendas en la aplicación móvil.",
  "FoodC_edit_DOXu_EYnd": "Ejemplo: “llamar a la puerta, no tocar el timbre.” También puedes subir fotos de los alrededores para ayudar al socio repartidor a entregar tu pedido en el lugar correcto.",
  "soda-c-i18n---将赔付给您1张%s折扣券": "Obtienes un cupón del %s de descuento.",
  "FoodC_education_1_If_iWDm": "1. Si no te es posible seguir las recomendaciones anteriores para recibir un pedido, podría considerarse que el inconveniente relacionado con la entrega fue tu responsabilidad. Esto incluye, pero no se limita a, situaciones en donde:",
  "soda-c-i18n---送您%s张免配送费券；": "Te daremos %s cupón para un envío gratis.",
  "FoodB_supplement_Network_connection_RVcl": "Falló la conexión. Comprueba tu configuración de red.",
  "FoodC_202408_IMjl_ywFc": "Cambiar",
  "FoodC_page_Rewards_will_NhMd": "Tu premio se agregará a tu cuenta dentro de las 48 horas de haber realizado una invitación exitosa. Para ver los premios, ve a {{\t\nproductname_didi_food}} > Billetera > Cupones",
  "FoodC_202410_pHYX_VjbD": "Cerrar"
}