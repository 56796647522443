import env from '../config/env'

const api = (path, projectId = 8358) => {
  return `https://mock.xiaojukeji.com/mock/${projectId}/${path}`
}

let mockApi = {}

if (env.isDev) {
  mockApi = {
    FEED_EFO_MORE: api('feed/efoMore'),
    NA_POP_DIALOG: api('growth/common/popDialog'),
    FEED_INDEX_V2: api('feed/indexV2'),
    FAST_BUY: api('bill/fastBuy'),
    BILL_INFO: api('sf/bill/info'),
    ORDER_CREATE_V2: api('order/createV2'),
    BILL_UPDATE: api('bill/update'),
    UPDATE_BILL_ALERT: api('bill/alert'),
    ADDRESS_LIST: 'https://mock.xiaojukeji.com/mock/8358/address/listPage',
    ADDRESS_TEXTSEARCH: 'https://mock.xiaojukeji.com/mock/8358/address/textSearch',
    SIDE_BAR: 'https://mock.xiaojukeji.com/mock/8358/common/sidebar',
    SHOP_INDEX: 'https://mock.xiaojukeji.com/mock/8358/storefront/shopIndex',
    ITEM_DETAIL: 'https://mock.xiaojukeji.com/mock/8358/item/detail',
    FEED_SEARCH: 'https://mock.xiaojukeji.com/mock/8358/feed/search',
    FEED_RECOMMEND: 'https://mock.xiaojukeji.com/mock/8358/feed/recommend',
    FEED_SEARCH_SUGGESTION: 'https://mock.xiaojukeji.com/mock/8358/feed/searchSuggestion',
    FETCH_COUPONlIST: 'https://mock.xiaojukeji.com/mock/822/coupon/fetchCouponList',
    CART_INFO: 'https://mock.xiaojukeji.com/mock/8358/cart/info',
    CART_SETAMOUNT: 'https://mock.xiaojukeji.com/mock/8358/cart/setItemsAmount',
    CART_SETITEM: 'https://mock.xiaojukeji.com/mock/8358/cart/setItemsAmount',
    PAY_GETPAYINFO: 'https://mock.xiaojukeji.com/mock/776/pay/getPayInfo',
    ORDER_LIST_V2: 'https://mock.xiaojukeji.com/mock/8358/order/orderListV2',
    GLOBAL_CART_BILL_LIST: 'https://mock.xiaojukeji.com/mock/8358/bill/list',
    GET_TOKEN: 'https://mock.xiaojukeji.com/mock/8358/common/getToken',
    ORDER_EVALUATION_TAG_LIST: api('evaluation/tagList'),
    ORDER_EVALUATION_EVALUATE: api('evaluation/evaluate'),
    ORDER_EVALUATION_GET_EVALUATE_INFO: api('evaluation/evaluationInfo'),
    PRODUCT_LIST: ' https://mock.xiaojukeji.com/mock/776/memberBenefit/productList',
    ORDER_LAYOUT_BATCH: 'https://mock.xiaojukeji.com/mock/8358/order/pageBatch',
    PAGE_BATCH_WITHOUT_LOGIN: 'https://mock.xiaojukeji.com/mock/8358/order/pageBatchWithoutLogin',
    ORDER_DETAIL_BATCH: 'https://mock.xiaojukeji.com/mock/8358/order/detailBatch',
    // ORDER_DETAIL_BATCH: 'https://mock.xiaojukeji.com/mock/8358/order/detailBatch',
    CANCEL_DUTY: 'https://mock.xiaojukeji.com/mock/822/order/cancelDutyV2',
    GET_CANCEL_REASON: 'https://mock.xiaojukeji.com/mock/822/order/getCancelReason',
    APPLY_CANCEL: 'https://mock.xiaojukeji.com/mock/822/cancel/apply',
    GET_CANCEL_FLOW: 'https://mock.xiaojukeji.com/mock/822/cancel/applyFlow',
    GET_MISS_ITEM_INFO: 'https://mock.xiaojukeji.com/mock/822/complaint/missItemInfo',
    GET_APPEAL_PROGRESS: 'https://mock.xiaojukeji.com/mock/822/complaint/process',
    MISS_ITEM_APPEAL: 'https://mock.xiaojukeji.com/mock/822/complaint/missItemsAppeal',
    GET_REFUND_REE: 'https://mock.xiaojukeji.com/mock/822/complaint/getRefundFee',
    POST_APPEAL_DATA: 'https://mock.xiaojukeji.com/mock/822/complaint/missItemsAppeal',
    ORDER_CANCEL: 'https://mock.xiaojukeji.com/mock/822/order/cancelV2',
    CANCEL_REASON: 'https://mock.xiaojukeji.com/mock/822/order/cancelReason',
    GET_WALLET_COUPONS: 'https://mock.xiaojukeji.com/mock/822/act-api/api/sidebar/validCouponsV2',
    CART_REDEEM: 'https://mock.xiaojukeji.com/mock/822/act-api/api/code/cartRedeem',
    SIDEBAR_EXCHANGE_COUPONS: 'https://mock.xiaojukeji.com/mock/822/act-api/api/code/sidebarRedeem',
    COMMON_CONFIG: 'https://mock.xiaojukeji.com/mock/8358/common/config',
    ADDRESS_UPDATE: 'https://mock.xiaojukeji.com/mock/8358/address/update',
    GET_USER_LAST_ORDER_ADDRESS:
      'https://mock.xiaojukeji.com/mock/8358/address/getUserLastOrderAddress',
    GET_BRANDINFO: 'https://mock.xiaojukeji.com/mock/8358/storefront/brandInfo',
    GET_BRAND_SHOPLIST: 'https://mock.xiaojukeji.com/mock/8358/storefront/brandShopList',
    GET_ADDRESS_BY_LOCATION: 'https://mock.xiaojukeji.com/mock/8358/address/getCurrentPoi',
  }
}

export default mockApi
