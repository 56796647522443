module.exports = {
  "已选择": "Selected",
  "小费": "Gorjeta",
  "FoodC_update_The_expected_ZzFg": "O horário estimado da entrega tem como base o último horário estimado de entrega.",
  "FoodC_education__2_jwSo": "(2) Deixar na porta (com uma mensagem)",
  "FoodB_Overview_AasD_LvfW": "Ver mais",
  "CS_page_Courier_Phone_ghUr": "Nº de telefone do entregador",
  "FoodC_web_Application_Progress_UYtG": "Status da solicitação",
  "FoodC_001_The_WEB_Iizt": "Não há suporte para pagamentos pelo PayPay pela versão web no momento. Para usar o PayPay, baixe o {{download}}",
  "FoodC_page_Upon_discovery_baTq": "O cupom não pode ser trocado ou resgatado em dinheiro. A violação das regras do cupons pode resultar na recuperação do valor e na suspensão do perfil",
  "FoodC_homepage_Enter_delivery_IZAu": "Insira o endereço de entrega",
  "FoodC_dish_Are_you_TaUN": "Deseja deixar a página da promoção?",
  "FoodC_Card2_When_the_sFOa": "Aberto às",
  "FoodC_education__1_vwgm": "(1) Entregar na porta",
  "soda-c-i18n---早间：%s-%s": "Manhã: %s-%s",
  "FoodB_202303_YWUL_hwNF": "OK",
  "soda-c-i18n---延迟送达%s分钟以上": "Atraso superior a %s minutos",
  "FoodC_stores_All_stores_clRv": "Todos os estabelecimentos",
  "FoodC_education_4_Customers_laxR": "4. Retire seu pedido em até 10 minutos após a chegada do(a) entregador(a) parceiro(a) ao local de entrega.",
  "FoodC_dish_XX_YY_opCV": "Termina em",
  "FoodC_perception_Tips_hZWk": "Gorjeta",
  "FoodC_education__1_qvVC": "(1) Você não retira seu pedido dentro do prazo",
  "FoodC_method_Wait_keAM": "Aguardar",
  "soda-c-i18n---请告诉我们，帮助我们一起改善": "Entre em contato e ajude-nos a melhorar",
  "soda-c-i18n---个人中心的“优惠券”": " Conta > Cupons.",
  "FoodC_supplement_Return_wVpv": "Voltar",
  "FoodC_2022__SEQk": "Insira a gorjeta",
  "soda-c-i18n---1、超时赔付服务是%s为您免费提供的服务，当前订单（不含已取消订单）未在预计送达时间内送达，%s将赔付给您一定金额的优惠券，订单完成后可以在中查看。": "1. A compensação por uma entrega atrasada é nossa maneira de pedir desculpas. Caso seu pedido não seja entregue até o horário estimado (exceto por pedidos cancelados), a %s dará a você um ou mais cupons gratuitos. Você pode vê-los após a conclusão do pedido, acessando",
  "FoodC_homepage_Search_for_CiMZ": "Encontrar refeições",
  "优惠券": "Voucher",
  "FoodD_suspensionpage_In_the_zGEU": "Sem mais resultados",
  "FoodB_reason_Contact_Courier_Fqog": "Entrar em contato com o(a) entregador(a) parceiro(a)",
  "FoodC_progress_Order_canceled_KDSH": "Pedido cancelado",
  "FoodC_education_Meal_picking_ziun": "Regras de entrega",
  "soda-c-i18n---订单实际送达时间晚于预计送达时间%s分钟以上、%s分钟以下，": "Se o seu pedido for entregue de %s a %s minutos após o horário estimado,",
  "FoodC_education_1_Customers_Gpbb": "1. O(a) entregador(a) parceiro(a) entregará seu pedido com base na opção selecionada:",
  "FoodC_perception_Tip_exceeds_VSFf": "O valor da gorjeta excede o limite",
  "FoodC_sales_Condition_of_lnwg": "A embalagem estava lacrada? (opcional)",
  "soda-c-i18n---送您1张%s折扣券；": "daremos a você 1 cupom de %s de desconto.",
  "productname_didi_food": "99Food",
  "FoodC_revision_Contact_customer_XbFk": "Fale conosco",
  "分钟": "min",
  "soda-c-i18n---现金": "Em dinheiro",
  "sailing.d-api.common.页面获取失败，请稍后重试": "Não foi possível obter a página, tente novamente mais tarde",
  "FoodC_education__1_mJTG": "(1) Se o estabelecimento já tiver confirmado e aceitado o pedido, você poderá usar o recurso “Cancelar pedido” para entrar em contato com o estabelecimento e solicitar o cancelamento. Se a loja não aceitar a solicitação porque já preparou o pedido ou por outro motivo razoável, você não poderá cancelar o pedido gratuitamente. Caso insista em cancelar ou se recuse a aceitar o pedido, no todo ou em parte, você deverá pagar todas as taxas pertinentes ao pedido.",
  "FoodC_education_Principles_of_RKEk": "Regras para resolver conflitos relacionados à entrega",
  "soda-c-i18n---当前订单（不含已取消订单）未在预计送达时间内送达，%s将赔付给您一定金额的优惠券，订单完成后可以在": "Se um pedido não for entregue até o horário estimado (exceto por pedidos cancelados), a %s dará a você um ou mais cupons como pedido de desculpas. Após a conclusão do pedido, você pode vê-los acessando ",
  "soda-c-i18n---将赔付给您1张%s优惠券": "Você recebe um cupom de %s de desconto",
  "FoodC_dish_Purchase_NrCl": "Pedir",
  "FoodC_page_Invite_friends_oGuj": "Convide um amigo para experimentar a {{\t\nproductname_didi_food}}. Você também receberá um [cupom] de{{\t\nproductname_didi_food}} caso seu amigo faça um pedido completo, sem pedido de reembolso, no prazo de até 14 dias após o recebimento do convite e antes do cupom expirar, mesmo se o cupom não for utilizado no pedido",
  "FoodC_popup_After_closing_ujoW": "Se você sair desta página, os itens selecionados não serão salvos",
  "FoodC_page__KWIm": "Entendi",
  "FoodC_remark_Enjoy_your_OvmD": "Bom apetite!",
  "提交申请": "Submit Request",
  "支付失败": "Falha no pagamento",
  "soda-c-i18n---超时赔付规则说明": "Compensação por atraso na entrega",
  "FoodC_education_5_Cash_zBuY": "5. Ao fazer um pedido pago em dinheiro, verifique se você tem a quantia necessária para fazer o pagamento.",
  "FoodC_sidebar_Scan_QR_ONzJ": "Digitalize com a câmera",
  "FoodC_sidebar_Use_the_huoZ": "Instale o app para pedir quando quiser",
  "取消": "Cancel",
  "FoodC_education_3_If_wduh": "3. Se um conflito relacionado à entrega não for causado pelo(a) entregador(a) parceiro(a), como um problema do produto ou o mau tempo, assumiremos a responsabilidade",
  "FoodC_sidebar_Update_successfully_cuyI": "Atualizado com sucesso",
  "FoodC_education__1_IIfh": "(1) Você cancela antes de o estabelecimento ter aceitado o pedido usando o recurso “Cancelar pedido”, na página Detalhes do pedido.",
  "FoodC_details_Refund_Details_sGIk": "Detalhes do reembolso",
  "FoodC_page_This_special_fYuo": "Essa promoção é oferecida somente em áreas de {{\t\nactivity_city}} onde {{\t\nproductname_didi_food}} está disponível.",
  "FoodC_page_For_any_IWNZ": "Para esclarecer dúvidas sobre essa promoção, entre em contato com o Atendimento ao Cliente em {{contact_email}}",
  "支付": "Pagar",
  "FoodC_reasons_Enter_a_Pnzg": "Descreva o problema em detalhes",
  "Entrega_name_99_Flash_maAd": "99Entrega",
  "支付方式": "Payment Method",
  "FoodC_education_Basic_requirements_hIbv": "Regras básicas de entrega para clientes",
  "sailing.c-api-intl.你最多可以下单{0}的货到付款单": "A opção “Pagar na entrega” está disponível para pedidos até {{0}}",
  "FoodC_homepage_Now_place_Kmkm": "Receba refeições diretamente na sua casa",
  "FoodC_education_In_order_SRIb": "Esta política de cancelamento foi elaborada para ajudar todos os usuários a terem uma experiência justa e confiável ao usarem o aplicativo {{brand_name}}.",
  "FoodC_education__4_QYXm": "(4) Você optou por dinheiro como forma de pagamento e se recusa a pagar na hora de retirar o pedido",
  "FoodC_education_In_the_MKeB": "Você não será responsabilizado por um cancelamento nas seguintes situações:",
  "FoodC_education__3_ltNM": "(3) Outros fatores fora do seu controle que causam o cancelamento do pedido, como quando não conseguimos encontrar um(a) entregador(a) parceiro(a) para entregá-lo ou outro problema da nossa parte.",
  "FoodC_presentation_DiDi_Delivery_vrwd": "99Entrega",
  "更多": "More",
  "FoodC_supplement_Modify_Mobile_ZJkT": "Para modificar seu número de telefone ou senha, adicione um endereço de e-mail ou remova sua conta",
  "soda-c-i18n---送您1张%s优惠券；": "daremos a você 1 cupom de %s de desconto.",
  "FoodD_problem_I_see_NhGG": "OK",
  "FoodC_sales_If_the_TsPm": "Para nos ajudar a verificar o problema com o estabelecimento e o entregador parceiro, informe se o item estava em uma embalagem lacrável, ou se foi lacrado de outra forma (por exemplo, grampeado).",
  "FoodOpC_backend_Copy_HxOc": "Copiar",
  "FoodC_perception_Sorry_you_FKSy": "Você só pode adicionar até 15 itens ao seu carrinho",
  "帮助中心": "Help Center",
  "FoodC_sales_Supplementary_information_ihyk": "Detalhes adicionais",
  "FoodC_education__2_uVUK": "(2) O estabelecimento ao qual você fez o pedido está fechado, um item está esgotado, ocorreu um acidente ou o pedido foi cancelado pelo estabelecimento ou pelo(a) entregador(a) parceiro(a) por outro motivo. Nesse caso, você receberá um reembolso total.",
  "FoodC_001_This_operation_Dwog": "Isso removerá todos os itens do seu carrinho. Deseja continuar?",
  "FoodC_all_No_reward_ocGJ": "Não há recompensas",
  "FoodC_education_In_order_AZCw": "Estas regras de entrega foram elaboradas para ajudar todos os usuários a terem uma experiência justa e confiável ao usarem o aplicativo {{brand_name}}. No caso de um conflito relacionado à entrega, serão aplicadas as regras a seguir",
  "FoodC_page_Your_invited_sPpR": "O amigo que você convidou deve ser um cliente novo da {{\t\nproductname_didi_food}}. Descontos no primeiro pedido são limitados a um por cliente (cada cliente deve ter um único número de telefone, dispositivo e conta para pagamento)",
  "FoodC_web_Only_1_ZddK": "Limite de 1 item",
  "FoodC_progress_Order_completed_joIL": "Pedido finalizado",
  "FoodC_page_Rewards_are_dWeY": "A recompensa é emitida com base na cidade em que a [pessoa indicada] está localizada",
  "FoodC_education__2_KZTZ": "(2) O(A) entregador(a) parceiro(a) não consegue entrar em contato com você",
  "FoodC_education_In_the_nIgD": "Você será responsabilizado por um cancelamento nas seguintes situações:",
  "FoodC_education_Platform_Delivery_MJPQ": "Entrega da plataforma",
  "FoodB_Overview_udmx_lJTl": "Erro ao carregar. Toque para tentar novamente.",
  "FoodC_dish_New_guest_bkGC": "No primeiro pedido",
  "提交": "Entregar",
  "soda-c-i18n---将赔付给您%s张免配送费券": "Você recebe %s cupom de entrega gratuita",
  "FoodC_all_You_haven_lSmt": "Você ainda não convidou nenhum amigo. Convide alguém",
  "FoodC_dish_Look_again_fJrp": "Agora não",
  "FoodC_remark_Thank_you_ENoY": "Obrigada por escolher a {{brand_name}}",
  "soda-c-i18n---提交申请": "Solicitar cancelamento",
  "soda-c-i18n---联系商家": "Fale com o estabelecimento",
  "FoodC_dish_Leaving_LwWJ": "Sair",
  "FoodC_education__2_KHhl": "(2) Se você não chegar ao local da entrega dentro do prazo especificado, informar incorretamente o endereço de entrega ou o número de telefone, recusar uma ligação do(a) entregador(a) parceiro(a) ou, ainda, recusar a entrega, no todo ou em parte, sem um motivo válido, será necessário pagar o pedido.",
  "FoodC_education_Cancellation_Policy_fxrl": "Política de cancelamento",
  "FoodC_nonaffiliate_Courier_is_iunG": "O entregador parceiro está a caminho do estabelecimento para atender seu pedido",
  "FoodC_dish_Current_activity_Iyrv": "Esta promoção termina em {{arrived_time}}. Depois disso, as ofertas para novos usuários estarão indisponíveis",
  "FoodC_dish_You_will_AXAT": "Você só receberá ofertas dos restaurantes indicados abaixo.",
  "必选": "Não pode ser desabilitado",
  "FoodC_education_If_you_RTIV": "Se você pagar por um pedido e depois tiver que cancelar, serão aplicadas as regras a seguir:",
  "FoodC_education_2_Receiving_gFZl": "2. Verifique se o endereço de entrega e a forma de contato fornecidos estão corretos.",
  "soda-c-i18n---晚间：%s-%s": "Noite: %s-%s",
  "FoodC_page__iyoe": "Mostrar menos",
  "FoodC_page_The_threshold_yZFq": "Os limites de uso e os períodos de validade são exibidos nos cupons. Os cupons só podem ser usados durante o período de validade",
  "FoodC_education__3_TDsa": "(3) Você se recusa a aceitar o pedido, no todo ou em parte, sem uma justificativa",
  "FoodC_education_2_If_SoSk": "2. Se um conflito relacionado à entrega ocorrer porque o(a) entregador(a) parceiro(a) não seguiu nossas regras, poderemos responsabilizá-lo(a)",
  "FoodC_education__unk_aOCD": "{{delivery_type}} Regras do aplicativo",
  "soda-c-i18n---将赔付给您1张%s折扣券": "Você recebe um cupom de %s de desconto",
  "FoodC_education_1_If_iWDm": "1. Se você não conseguir cumprir os requisitos acima, poderá ser responsabilizado no caso de um problema relacionado à entrega. Isso inclui, entre outras, as situações em que:",
  "soda-c-i18n---送您%s张免配送费券；": "daremos a você %s cupom de entrega gratuita.",
  "确认": "Avançar",
  "FoodB_supplement_Network_connection_RVcl": "Falha na conexão. Verifique suas configurações de rede",
  "FoodC_page_Rewards_will_NhMd": "Sua recompensa será adicionada ao seu perfil até 48 horas após uma indicação bem-sucedida. Para ver as recompensas, acesse {{\t\nproductname_didi_food}} > Carteira > Cupons",
  "请选择支付方式": "Selecionar método de pagamento",
  "soda-c-i18n---订单实际送达时间晚于预计送达时间%s分钟以上，": "Se o seu pedido for entregue mais de %s minutos após o horário estimado,",
  "soda-c-i18n---取消订单": "Cancelar pedido",
  "FoodC_education_3_Customers_Zoec": "3. Mantenha seu telefone acessível durante a entrega para garantir que o(a) entregador(a) parceiro(a) consiga entrar em contato se necessário."
}