import API from '@/api'
import { debounce } from 'lodash-es'
import precision from '@/utils/precision'
import { BUSINESS_TYPE } from '@/const/cart'
import Vue from 'vue'
import { isStrategyShow } from '@/utils/baseUtil'

import { getItemById, temp, getHasDebounceParams, getItemOnlyParams } from '@/utils/cart'
const BACKUP_AMOUNT = '_backupAmount'
const DELAY = 400
const debounceRequest = debounce(temp, DELAY)
let LATEST_REQUEST_COUNT = 0
let debounceParamsList = []
function transRevision(revision) {
  if (revision === undefined) {
    return revision
  }
  return Math.floor(Number(revision) / 100)
}
const state = {
  cartInfo: {},
  cartId: '',
  revision: '', // 后端保留 revision 后两位，便于拓展
  prices: {},
  items: [],
  scene: 1,
  // 购物车loading态
  loading: false,
  amountSum: 0,

  cartInfoType: 1, // 1正常来源 2再来一单
  setItemAmountParamsList: [], // 存储 debounce 回调执行前所有的入参
}
const mutations = {
  setCartInfo(state, data) {
    const parseData = parseCartInfo(data)
    state.cartInfo = parseData
    state.cartId = parseData?.cartId
    state.prices = parseData?.prices
    state.items = parseData?.items
    state.revision = transRevision(data?.revision)
    // 购物车数量更新;
    const amountArr = []
    state.cartInfo?.items?.forEach(item => {
      amountArr.push(item.amount)
    })
    state.amountSum = amountArr.reduce(function (prev, cur) {
      return prev + cur
    }, 0)
  },
  setTempItem(state, data) {
    const { amount, mduId } = data
    const item = getItemById(state.items, mduId)
    if (item[BACKUP_AMOUNT] === undefined) item[BACKUP_AMOUNT] = item.amount
    item.amount = amount
  },
  setRevision(state, data) {
    state.revision = data.revision
  },
  setLoading(state, data) {
    state.loading = data.loading
  },
  setScene(state, data) {
    state.scene = data.scene
  },
  setAmountSum(state, data) {
    state.amountSum = data.amountSum
  },
  setCartInfoType(state, data) {
    state.cartInfoType = data
  },
  setItemAmountParamsList(state, data) {
    const { clear = false, params } = data
    if (clear) {
      state.setItemAmountParamsList = []
    } else {
      state.setItemAmountParamsList.push(params)
    }
  },
}
const actions = {
  /**
   * 加购菜调用接口的入口函数
   * 内部处理 是否是首次加购以及loading展示
   * 统一debounce
   * 处理使用哪个接口加购
   * 多个菜触发debounce并发请求处理
   * 统一处理返回值
   * *处理接口函数并发的问题
   * tobaccoConfirm wineConfirm 只有规格页会携带所以只需要判断最后一个入参
   */
  async addShopCartMainFn({ commit, state, dispatch }, { params, payload }) {
    // 收集所有加购信息
    debounceParamsList.push(params)
    commit('setLoading', { loading: true })

    // 为了让外部能感知到 异步请求结束 可以自行添加返回值
    let promiseResolve = null
    const promiseObj = new Promise(resolve => {
      promiseResolve = resolve
    })
    // 并发请求下，只保留匹配一致的结果
    ++LATEST_REQUEST_COUNT
    // debounce
    debounceRequest(async () => {
      addShopCartAwait(
        { commit, state, dispatch },
        { ...payload, paramsLast: params },
        promiseResolve,
      )
    })
    const res = await promiseObj
    return res
  },

  /**
   * @description: 获取mini购物车数据
   * @param {type}
   * @return
   * @param params
   */
  async cartInfo({ commit }, { shopId, scene }) {
    let res = {}
    commit('setLoading', { loading: true })
    try {
      res = await API.cartInfo({ shopId, scene })
      res = precision.transCartInfo(res)
      if (res?.toast?.content) handleToast(res)
      if (res?.alert?.content) handleAlert(res)
      commit('setCartInfo', res)
      commit('cart/setCartInfo', res, { root: true })
      commit('setLoading', { loading: false })
    } catch (e) {
      commit('setLoading', { loading: false })
      return false
    }
    return res
  },

  setAmountSum({ commit, state }, params) {
    const amountArr = []
    params?.items?.forEach(item => {
      amountArr.push(item?.amount)
    })
    const arrNum = amountArr.reduce(function (prev, cur) {
      return prev + cur
    }, 0)
    commit('setAmountSum', { amountSum: arrNum })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}

function handleToast(res) {
  const show = res?.toast?.strategy
    ? isStrategyShow('special_cate_limit', res?.toast?.strategy)
    : true
  show && Vue.prototype.$message.warning(res?.toast?.content)
}
function handleAlert(res) {
  new Vue()
    .$createAlert({
      type: 'alert',
      content: res?.alert?.content,
      icon: 'richic-alert',
    })
    .show()
}
/**
 * *购物车加购接口相关 功能函数
 * 这部分是重构的逻辑 有可能没有被启用
 * 注：后期烟酒弹窗逻辑要开放给菜品卡片 加购前逻辑层面临重构 到时候一起测试
 */
function addShopCartAwaitBefore({ commit, state }) {
  // addShopCartAwaitBefore 处理请求之前的数据和参数

  // 购物车 revision 处理
  const { revision } = state
  if (typeof revision === 'number') {
    commit('setRevision', { revision: revision + 1 })
  }
  // 分离加购信息
  let hasMduIdList = []
  let noMduIdList = []
  debounceParamsList.forEach(params => {
    if (!params) return
    if (params.mduId) {
      hasMduIdList.push(params)
    } else {
      noMduIdList.push(params)
    }
  })
  debounceParamsList = []
  // 去重
  noMduIdList = getItemOnlyParams(noMduIdList, 'itemId')
  hasMduIdList = getHasDebounceParams(hasMduIdList, 'mduId')

  return {
    hasMduIdList,
    noMduIdList,
  }
}

async function addShopCartAwait(
  { commit, state, dispatch },
  { pendingCb, toastCb, paramsLast },
  promiseResolve,
) {
  const { noMduIdList, hasMduIdList } = addShopCartAwaitBefore({
    commit,
    state,
  })
  const awaitION = LATEST_REQUEST_COUNT
  let load = null
  pendingCb && pendingCb(noMduIdList.length)
  try {
    // 首次加购请求处理
    const promiseArr = []
    let { cartId, revision } = state
    if (noMduIdList.length) {
      load = new Vue().$rLoading()
    }
    noMduIdList.forEach((params, index) => {
      const {
        shopId,
        nodeList,
        scene,
        cHasWine,
        cHasTobacco,
        tobaccoConfirm,
        wineConfirm,
        buyType,
      } = params
      const awaitParams = {
        shopId,
        nodeList,
        revision: ++revision,
        scene,
        businessType: BUSINESS_TYPE.grocery,
        cHasWine,
        cHasTobacco,
        buyType,
      }
      tobaccoConfirm === 1 && (awaitParams.tobaccoConfirm = tobaccoConfirm)
      wineConfirm === 1 && (awaitParams.wineConfirm = wineConfirm)
      promiseArr.push(
        new Promise((resolve, reject) => {
          // 这里延时是因为后端接口对并发不友好的妥协处理
          setTimeout(() => {
            API.addItem(awaitParams)
              .then(res => {
                resolve(res)
              })
              .catch(err => {
                reject(err)
              })
          }, 200 * index)
        }),
      )
    })
    // 已在购物车请求处理
    if (hasMduIdList.length) {
      const { tobaccoConfirm, wineConfirm, scene } = paramsLast
      // isFromCart ? getItemsAmount(state.items) :hasMduIdList
      const content = hasMduIdList
      const awaitParams = {
        cartId,
        content,
        revision: ++revision,
        scene,
        businessType: BUSINESS_TYPE.grocery,
      }
      tobaccoConfirm === 1 && (awaitParams.tobaccoConfirm = tobaccoConfirm)
      wineConfirm === 1 && (awaitParams.wineConfirm = wineConfirm)
      promiseArr.push(API.setItemsAmount(awaitParams))
    }
    commit('setRevision', { revision })
    Promise.all(promiseArr)
      .then(res => {
        // *处理返回数据 多个
        const refreshRes = addShopCartAwaitAfter({ commit }, res, {
          awaitION,
          paramsLast,
          load,
          toastCb,
        })
        promiseResolve(refreshRes)
      })
      .catch(e => {
        addShopCartAwaitCatchAfter({ commit, dispatch }, e, {
          awaitION,
          paramsLast,
          load,
          toastCb,
        })
        promiseResolve(null)
      })
  } catch (e) {
    addShopCartAwaitCatchAfter({ commit, dispatch }, e, {
      awaitION,
      paramsLast,
      load,
      toastCb,
    })
    promiseResolve(null)
  }
}
function addShopCartAwaitAfter({ commit }, resList, { awaitION, load, toastCb }) {
  // 返回结果处理
  let res = resList[resList.length - 1]
  res = precision.transCartInfo(res) || {}
  if (res?.toast?.content) {
    handleToast(res)
    toastCb && toastCb(res?.toast)
  }
  if (res?.alert?.content) handleAlert(res)
  if (awaitION === LATEST_REQUEST_COUNT) {
    load?.close()
    commit('setLoading', { loading: false })
    commit('setCartInfo', res)
    commit('cart/setCartInfo', res, { root: true })
  }
  return res
}
async function addShopCartAwaitCatchAfter(
  { commit, dispatch },
  e,
  { awaitION, paramsLast, load, toastCb },
) {
  if (awaitION === LATEST_REQUEST_COUNT) {
    // 更新异常提示
    // 恢复数据 直接调用cartInfo接口 shopId
    load?.close()
    commit('setLoading', { loading: false })
  }
  if (e?.errno === 45313) {
    // 烟酒未满18岁
    toastCb &&
      toastCb({
        type: 'noLegalAge',
      })
  }
  e?.errmsg && Vue.prototype.$message.warning(e?.errmsg)

  const { shopId } = paramsLast
  const res = await dispatch('cartInfo', { shopId })
  res && dispatch('setAmountSum', res)
}

/**
 * 解析 购物车items 等数据
 */
function parseCartInfo(data = {}) {
  const { items = [] } = data
  // 解析 主菜的赠品数量
  items.forEach((item, index) => {
    if (+item.mduType === 2) {
      const masterCateIndex = index - 1
      if (masterCateIndex < 0) return
      items[masterCateIndex].feGiftAmount = item.amount
    }
  })
  return data
}
