<template>
  <div
    class="stepper-number-box"
    :class="{ disabled: disabled, 'rule-disabled': max === 1 && min === 1 }"
  >
    <div
      class="minus-sign"
      :class="{ action: copies > minCopies, 'minus-sign-disabled': copies === 1 }"
      @click="reduceCopies"
    >
      <i class="icon-v3_outlined_subtract_button Group-3-class" />
    </div>
    <div class="copies-num" v-text="copies"></div>
    <div class="plus-sign" :class="{ action: copies < maxCopies }" @click="addCopies">
      <i class="icon-v3_outlined_add_button Group-3-class Group-3-class-add" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CateAddStepper',
  props: {
    disabled: Boolean,
    value: {
      type: Number,
      default: 1,
    },
    min: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: Infinity,
    },
  },
  data() {
    return {
      copies: 1,
      minCopies: 1,
      maxCopies: Infinity,
    }
  },
  watch: {
    value(nVal) {
      this.copies = nVal
    },
    min(nVal) {
      this.minCopies = nVal
    },
    max(nVal) {
      this.maxCopies = nVal
    },
  },
  mounted() {
    this.copies = this.value
    this.minCopies = this.min
    this.maxCopies = this.max
  },
  methods: {
    reduceCopies() {
      if (this.copies <= this.minCopies) return
      this.copies--
      this.$emit('input', this.copies)
    },
    addCopies() {
      if (this.disabled || this.copies >= this.maxCopies) return
      this.copies++
      this.$emit('input', this.copies)
    },
  },
}
</script>

<style lang="scss" scoped>
.stepper-number-box {
  display: flex;
  padding: 10px 8px;
  width: 128px;
  height: 48px;
  text-align: center;
  background: #ffffff;
  border: 0.89px solid #999999;
  border-radius: 4px;
}
.minus-sign,
.plus-sign {
  width: 28px;
  height: 28px;
  // line-height: 28px;
  padding: 4px;
  font-size: 20px;
  color: #cccccc;
  cursor: pointer;
  user-select: none;
  font-weight: bold;
  i {
    display: block;
    color: #0b0b0d;
    position: relative;
    top: rem(2);
  }
  .Group-3-class {
    color: #0b0b0d;
  }
}
.minus-sign.action,
.plus-sign.action {
  .Group-3-class {
    color: #0b0b0d;
  }
  .Group-3-class-add {
    color: #0b0b0d;
  }
}
.copies-num {
  flex: 1;
  height: 28px;
  line-height: 28px;
  font-family: 'Aspira Medium';
  font-weight: 500;
  font-size: 18px;
  color: #000000;
}
.stepper-number-box.disabled {
  border-color: #cccccc;
  .copies-num {
    color: #999;
  }
  .minus-sign,
  .plus-sign {
    color: #cccccc !important;
    .Group-3-class {
      color: #cccccc !important;
    }
  }
}
.stepper-number-box.rule-disabled {
  border-color: #cccccc;
}
@media screen and (max-width: 768px) {
  .stepper-number-box {
    padding: rem(30) rem(20);
    width: rem(264);
    height: auto;
    border: none;
    background: #f5f5f7;
    border-radius: rem(32);
  }
  .minus-sign,
  .plus-sign {
    padding: 0;
    width: 20px;
    height: 20px;
    font-size: 18px;
  }
  .copies-num {
    height: 20px;
    line-height: 20px;
    font-size: 20px;
  }
}
.minus-sign-disabled {
  .Group-3-class {
    color: #cacacc !important;
  }
}
</style>
