<template>
  <div class="next-level-footer">
    <el-button dark outline @click="cancel">{{ $t('FoodC_supplement_Return_wVpv') }}</el-button>
    <el-button
      :class="{ 'is-disabled': !nextReady }"
      type="primary"
      :disabled="disabled"
      @click="confirm"
    >
      {{ $t('确认') }}
    </el-button>
  </div>
</template>

<script>
export default {
  name: 'CateNextLvFooter',
  props: {
    disabled: Boolean,
    nextReady: Boolean,
  },
  data() {
    return {
      copies: 1,
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    confirm() {
      this.$emit('confirm')
    },
  },
}
</script>
<style lang="scss">
.next-level-footer .el-button {
  width: 140px;
}
</style>
<style lang="scss" scoped>
.next-level-footer {
  text-align: right;
}
.is-disabled {
  opacity: 0.5;
}
</style>
