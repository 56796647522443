<template>
  <div
    v-if="contentList && contentList.length"
    class="collapse-com-wrappper collapse-com-wrappper-v2"
  >
    <el-Collapse v-model="activeNames">
      <el-collapse-item
        v-for="(item, itemIndex) in contentList"
        :key="'item' + itemIndex"
        :name="item.contentId"
        :disabled="isH5"
      >
        <template slot="title">
          <ContentCom
            :required-warning="item._requiredWarning || false"
            :require-status="getRequireTip(item)"
            :content-name="item.contentName"
            :item-num-desc="getGroupTip(item)"
          />
        </template>
        <div class="options-box">
          <OptionsCom
            v-for="(data, index) in item.subItemList"
            :key="index"
            :data="data"
            :type="getCheckType(item)"
            :disabled="disabled"
            :next="!isLeaf(data)"
            :copies="getItemCounter(data)"
            :checked="isSelected(data)"
            :format-display-name="formatDisplayName"
            :sum-display-price="sumDisplayPrice"
            :disable-status="getDisableStatus(item, data)"
            :counter-disable-status="getCounterDisableStatus(item)"
            @item-click="itemClick"
            @counter-add="counterAdd"
            @counter-remove="counterRemove"
            @disabled-item-click="disabledItemClick(itemIndex)"
          />
        </div>
      </el-collapse-item>
    </el-Collapse>
  </div>
</template>

<script>
import strategy from '@/components/Tree/strategy'
import { mapState } from 'vuex'
import ContentCom from './content.vue'
import OptionsCom from './optionsCom.vue'
export default {
  name: 'CollapseCom',
  components: {
    ContentCom,
    OptionsCom,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    contentList: Array,
    disabled: Boolean,
    // eslint-disable-next-line vue/require-default-prop
    isLeaf: Function,
    // eslint-disable-next-line vue/require-default-prop
    isSelected: Function,
    // eslint-disable-next-line vue/require-default-prop
    getGroupTip: Function,
    // eslint-disable-next-line vue/require-default-prop
    getCheckType: Function,
    // eslint-disable-next-line vue/require-default-prop
    getRequireTip: Function,
    // eslint-disable-next-line vue/require-default-prop
    getItemCounter: Function,
    // eslint-disable-next-line vue/require-default-prop
    formatDisplayName: Function,
    // eslint-disable-next-line vue/require-default-prop
    sumDisplayPrice: Function,
    // eslint-disable-next-line vue/require-default-prop
    getDisableStatus: Function,
    // eslint-disable-next-line vue/require-default-prop
    getCounterDisableStatus: Function,
  },

  data() {
    return {
      activeNames: [],
    }
  },
  computed: {
    ...mapState({
      isH5: ({ common }) => common.isH5,
    }),
  },
  watch: {
    contentList(nVal) {
      this.init(nVal)
    },
  },
  mounted() {
    this.init(this.contentList)
  },
  methods: {
    init(val) {
      if (val && val.length) {
        this.activeNames = val.map(item => {
          return item.contentId
        })
      }
    },
    itemClick(data) {
      if (strategy.isLeaf(data) && this.disabled) return
      this.$emit('item-click', data)
    },
    counterAdd(data) {
      if (this.disabled) return
      this.$emit('counter-add', data)
    },
    counterRemove(data) {
      if (this.disabled) return
      this.$emit('counter-remove', data)
    },
    disabledItemClick(itemIndex) {
      this.$emit('disabled-item-click', itemIndex)
    },
  },
}
</script>
<style lang="scss">
.collapse-com-wrappper {
  .el-collapse-item > div:first-child {
  }
  .el-collapse-item__header {
    margin-left: 24px;
    margin-right: 24px;
    min-height: 60px;
    height: auto;
    line-height: 1;
    color: #ebeef5;
    background: #f7f7f7;
    border: none;
    border-bottom: 1px solid #ebeef5;
    overflow: hidden;
    &.is-active {
      border-bottom: none;
    }
    .el-collapse-item__arrow {
      color: #000;
      display: block;
      margin: 0;
      padding: 4px;
      font-size: 14px;
      transform: rotate(90deg);
      &.is-active {
        transform: rotate(-90deg);
      }
    }
  }
  .el-collapse,
  .el-collapse-item__wrap {
    border: none;
  }
  .el-collapse-item__content {
    padding-bottom: 0;
  }
}
.collapse-com-wrappper-v2 {
  .el-collapse-item > div:first-child {
    background: #fff;
  }
  .el-collapse-item__header {
    background: #fff;
  }
}
@media screen and (max-width: 768px) {
  .collapse-com-wrappper .el-collapse-item__header {
    margin-left: rem(40);
    margin-right: rem(40);
    min-height: rem(124);
    height: auto;
  }
  .el-collapse-item__arrow {
    display: none !important;
  }
}
</style>
<style lang="scss" scoped src="./index.scss"></style>
