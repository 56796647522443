import { getMsgForCheckLimit } from '@/utils/cart'
import { mapState } from 'vuex'

export default {
  data() {
    return {}
  },
  computed: {
    ...mapState({
      amountSumMixin: ({ cartV2 }) => cartV2.amountSum,
    }),
    cartLimitNumMixin() {
      return 30
    },
  },
  methods: {
    // 检验库存/限购
    checkLimitAmountMixin(item, amount, addParams = {}, dishInstance) {
      let {
        purchaseLimit = Infinity, // purchaseLimit  商品限购数  0标识不限购
        // stock = Infinity, // stock    库存数
        amount: oldAmount = 0, // 加购之前的商品数
        curBuyType: oldBuyType = 0, // 记录旧的类型
        // feGiftAmount = 0, // 现有的赠品数量
      } = item
      const { buyType = 0 } = addParams
      purchaseLimit === 0 && (purchaseLimit = this.cartLimitNumMixin)
      const moreAmount = amount - oldAmount
      // const allAmount = amount + feGiftAmount // 主菜 + 赠品
      const reduceStatus = moreAmount < 0
      if (reduceStatus) return true
      // *未变更菜品数 这个情况的处理逻辑很特殊 有多重边界情况改动需要小心
      const noChangeAmount = moreAmount === 0
      if (dishInstance && noChangeAmount) {
        // 规格页如果不变更数量跳过校验
        const { wineConfirm, tobaccoConfirm } = addParams
        if (tobaccoConfirm === 1 || wineConfirm === 1) {
          return true
        } else if (buyType && +buyType !== +oldBuyType) {
          // 没有变更数量，但是切换类型，需要触发接口更新
          return true
        } else {
          dishInstance?.onClose()
          return false
        }
      }
      // 购物车数量限制
      const cartLimitNum = noChangeAmount ? this.cartLimitNumMixin - 1 : this.cartLimitNumMixin // 这是因为如果数量没有变更需要校验 >= 30 所以这里设置为29(目前这段逻辑应该不会触发了，但是先保留)
      const limitCartNumStatus = this.amountSumMixin + moreAmount > cartLimitNum
      if (limitCartNumStatus) {
        // eslint-disable-next-line no-unused-vars
        const { limitMsg, limitType } = getMsgForCheckLimit(null, null, null, 'Limit', {
          cartLimitNum: this.cartLimitNumMixin,
        })
        this.showWarnMixin(limitMsg)
        return false
      }
      // 限购限制
      // eslint-disable-next-line no-unused-vars
      // const { limitAmount, limitMsg, limitType } = getMsgForCheckLimit(
      //   stock,
      //   purchaseLimit,
      //   this.cartLimitNumMixin,
      //   '',
      //   { cartLimitNum: this.cartLimitNumMixin },
      // )
      // if (limitAmount === 0 || allAmount > (noChangeAmount ? limitAmount - 1 : limitAmount)) {
      //   this.showWarnMixin(limitMsg)
      //   return false
      // }
      return true
    },

    showWarnMixin(msg) {
      msg && this.$utils.infoToast(msg)
    },
  },
}
