module.exports = {
  "已选择": "已选择",
  "FoodC_edit_stZr_IBeA": "其他",
  "FoodC_check_HTib_sWqA": "准备下单",
  "FoodC_202409_dTTH_bjFQ": "该订单已完单或不存在，无法查看",
  "FoodC_edit_AxtX_PAiY": "例如：C栋1118",
  "FoodC_202412_PwIG_QPFY": "输入地址",
  "FoodB_Overview_AasD_LvfW": "查看更多",
  "FoodC_edit_miEX_KTUj": "楼层/房间号",
  "CS_page_Courier_Phone_ghUr": "骑手电话",
  "FoodC_Website_At_present_XpFw": "目前Web站暂未上线兑换功能，请前往App端进行兑换码兑换",
  "FoodC_20240607_eLRx_hJRj": "暂不支持电脑访问",
  "FoodC_edit_rpfC_Jyhp": "楼栋/楼层/单元/房间号",
  "FoodC_202408_wten_GUVt": "选择收餐地址",
  "FoodC_edit_Hxmm_vrkU": "居民楼",
  "FoodC_202408_PJcq_SjqG": "抱歉，店铺没有留联系方式",
  "FoodC_001_The_WEB_Iizt": "WEB版本暂不支持paypay支付，使用paypay支付请下载{{download}} ",
  "FoodC_page_Upon_discovery_baTq": "一经发现任何违规、套现行为，将追回相关金额或者封停账号，且依法追究其法律责任",
  "FoodC_homepage_Enter_delivery_IZAu": "输入配送地址",
  "FoodC_edit_bZFb_ghVG": "请在酒店楼外见面",
  "FoodC_edit_qhyi_ihvA": "例如：中心大道88号，中心大楼，幸福大酒店",
  "FoodC_Card2_When_the_sFOa": "开店时间",
  "soda-c-i18n---早间：%s-%s": "早间：%s-%s",
  "FoodB_202303_YWUL_hwNF": "好的",
  "FoodC_Storefront_iOFy_aaef": "商家暂未参与活动",
  "soda-c-i18n---延迟送达%s分钟以上": "延迟送达%s分钟以上",
  "FoodC_stores_All_stores_clRv": "全部商家",
  "FoodC_edit_SQwr_AVSp": "公寓名称",
  "FoodC_edit_OVRs_CzUt": "酒店",
  "FoodC_dish_XX_YY_opCV": "仅剩",
  "FoodC_notice_fOyQ_lqIn": "当前现金支付不可用，请检查是否下单金额超限额或者有未完成的在途现金支付订单后，稍后重试。",
  "FoodC_edit_SKZZ_WpVu": "建筑类型",
  "FoodC_202408_HkLu_Mwgv": "位置权限未授权",
  "soda-c-i18n---个人中心的“优惠券”": "个人中心的“优惠券”",
  "FoodC_202408_QAer_OYaB": "嗨，欢迎光临！",
  "FoodC_202408_leda_oOpl": "轻松跟踪订单",
  "soda-c-i18n---1、超时赔付服务是%s为您免费提供的服务，当前订单（不含已取消订单）未在预计送达时间内送达，%s将赔付给您一定金额的优惠券，订单完成后可以在中查看。": "1. 超时赔付服务是%s为你免费提供的服务，当前订单（不含已取消订单）未在预计送达时间内送达，%s将赔付给你一定金额的优惠券，订单完成后可以在中查看。",
  "FoodC_homepage_Search_for_CiMZ": "搜索美食",
  "FoodC_edit_SkdG_WQKu": "请在酒店大堂见面",
  "FoodC_20240607_wfRH_rTAa": "商家信息",
  "FoodD_suspensionpage_In_the_zGEU": "到底了",
  "FoodC_202408_AFKG_hWIj": "超出配送范围了，请重新选择地址吧",
  "FoodB_reason_Contact_Courier_Fqog": "联系骑手",
  "FoodC_202412_nXPk_kwtQ": "未找到地址？调整关键词重新试下吧",
  "FoodC_edit_QrTY_mpUt": "例如：中心大道88号，中心大楼，幸福小区",
  "Entrega_order__YnnH": "DiDi Entrega Business",
  "FoodC_education_1_Customers_Gpbb": "1. 顾客可在下单前选择交餐方式，平台会要求骑手按照顾客所选择的方式进行处理：",
  "FoodC_perception_Tip_exceeds_VSFf": "小费超出额度限制",
  "FoodC_202408_reKH_lclj": "添加收餐地址",
  "FoodC_edit_hDGv_arOT": "请在楼下大厅见面",
  "soda-c-i18n---现金": "现金",
  "sailing.d-api.common.页面获取失败，请稍后重试": "页面获取失败，请稍后重试",
  "FoodC_education__1_mJTG": "（1）如果商家已确认接单，您可通过‘取消订单‘功能与商家协商申请取消订单；如商家因已出餐等合理理由不同意取消订单，您将不能免费取消订单；如您坚持取消订单或者拒收餐品，则订单相关费用将由您全部承担；",
  "FoodC_20240607_STdK_ZTMu": "本平台技术服务由{{brand_name}}提供",
  "FoodC_education_Principles_of_RKEk": "取餐纠纷处理原则",
  "soda-c-i18n---当前订单（不含已取消订单）未在预计送达时间内送达，%s将赔付给您一定金额的优惠券，订单完成后可以在": "当前订单（不含已取消订单）未在预计送达时间内送达，%s将赔付给你一定金额的优惠券，订单完成后可以在",
  "soda-c-i18n---将赔付给您1张%s优惠券": "将赔付给你1张%s优惠券",
  "FoodC_edit_iuIV_BOva": "10分钟",
  "FoodC_20240607_Olbe_DAqS": "加载失败",
  "FoodC_202408_SXSA_LasS": "绑定手机号，享受更多权益",
  "FoodC_page_Invite_friends_oGuj": "邀请好友成为{{\t\nproductname_didi_food}}新用户，好友领取您送出的红包，并在被介绍后14天内及活动时间截止前，完成订单且无退款，无论是否使用领取的红包下单，您均可获得{{\t\nproductname_didi_food}}[优惠券]奖励",
  "FoodC_202408_GeGr_xwdt": "配送联系更便捷",
  "提交申请": "提交申请",
  "支付失败": "支付失败",
  "FoodC_202408_xwYJ_IzdZ": "继续",
  "FoodC_referral_Copy_link_kCti": "复制链接",
  "FoodC_202408_ksyj_sXlp": "关于店铺",
  "FoodC_sidebar_Scan_QR_ONzJ": "扫描二维码",
  "FoodC_sidebar_Use_the_huoZ": "使用APP，随时随地享有美食",
  "取消": "取消",
  "FoodC_education_3_If_wduh": "3. 若因产品问题、天气问题等非人为因素导致交餐纠纷，平台承担责任",
  "FoodC_web_Customer_Service_EBqf": "客服",
  "FoodC_MVP_ASpO_MFfF": "订单详情",
  "FoodC_202412_dCuL_VUFU": "重新定位",
  "FoodC_edit_GoWo_Frbk": "楼层/单元/房间号",
  "FoodC_details_Refund_Details_sGIk": "退款详情",
  "FoodC_202412_bolI_YXBT": "已保存地址",
  "FoodC_page_This_special_fYuo": "本优惠活动仅限{{\t\nactivity_city}}开通{{\t\nproductname_didi_food}}区域",
  "FoodC_page_For_any_IWNZ": "关于本活动的任何问题，请联系{{contact_email}}客服",
  "Entrega_name_99_Flash_maAd": "99闪送",
  "FoodC_20240607_Upph_ldoj": "购买提醒",
  "支付方式": "支付方式",
  "FoodC_education_Basic_requirements_hIbv": "顾客取餐基本要求",
  "FoodC_202410_ZHQi_kNsP": "修改",
  "FoodC_202412_Oant_EESB": "下一步",
  "FoodC_edit_JdIz_uKvw": "在外面见面",
  "FoodC_education_In_order_SRIb": "为了使您在{{brand_name}}服务中有更好的体验，平台特制订取消规则，以供各方遵守。",
  "FoodC_1_mnqk_AcOb": "复制失败",
  "FoodC_202408_qXNe_iBNs": "获取当前位置失败",
  "FoodC_202408_LBac_RLgP": "请添加你的收餐地址，以方便骑手为您送餐",
  "FoodC_202408_sbmp_YFyl": "当前位置",
  "FoodC_202408_RRtT_ZjIG": "请添加收餐地址",
  "FoodC_20240607_hhNB_mCBD": "手机号",
  "FoodC_presentation_DiDi_Delivery_vrwd": "滴滴闪送",
  "更多": "更多",
  "FoodC_202408_ZOBi_xjfW": "搜索地址，如建筑物或公寓名称",
  "FoodC_supplement_Modify_Mobile_ZJkT": "修改手机号/修改密码/绑定邮箱/注销账户请",
  "FoodC_address_gNYi_HdzX": "保存失败",
  "FoodC_edit_fLUx_wHAP": "例如：我穿着花衬衫。还可上传您所在位置附近的参照物图，方便骑手准确送达。",
  "FoodD_problem_I_see_NhGG": "我知道了",
  "FoodC_202408_BsWi_bWTi": "编辑地址",
  "FoodC_202408_LtuD_oTxz": "取消",
  "FoodC_sales_If_the_TsPm": "如果外包装有封条或其他密封工具（如装订针等），请您告诉我们收餐时它们是否完好。信息仅用于平台更准确地与商家和骑手核实您的问题，信息不会影响对您的处理结果。",
  "FoodOpC_backend_Copy_HxOc": "复制",
  "FoodC_202408_uMeD_ECQU": "操作失败，请您重试",
  "FoodC_202408_KYqe_yVZD": "稍后处理",
  "FoodC_edit_kzdk_yzVc": "请在房间门口见面",
  "FoodC_Website_The_WEB_pBbW": "WEB版本暂不支持{{pay_name}}支付，使用{{pay_name}}支付请下载",
  "FoodC_Website_From_shop_Jatv": "从【shop name】点外卖，立刻下单|DiDi Food",
  "帮助中心": "帮助中心",
  "FoodC_edit_Fyzp_tERt": "请保持手机畅通，骑手到达后将会联系您，并在约定位置等待您{{time}}",
  "FoodC_202408_pSzr_CJqz": "保存地址",
  "FoodC_sales_Supplementary_information_ihyk": "补充信息",
  "FoodC_edit_IhsK_uKOF": "请选择",
  "FoodC_education__2_uVUK": "（2）因商家未营业、商品售罄、发生意外等原因订单被商家或骑手取消，订单费用将全部退回；",
  "FoodC_001_This_operation_Dwog": "此操作将删除购物车，是否继续？",
  "FoodC_202412_JiED_bPrx": "未找到地址，请输入正确的建筑物、街道和门牌号",
  "FoodC_page_Your_invited_sPpR": "您邀请的好友必须是{{\t\nproductname_didi_food}}新用户，每人仅限享受1次新用户首单优惠，同一手机号、同一设备、同一支付账号视为同一用户",
  "FoodC_edit_HEFc_Dppo": "请在我家楼外见面",
  "FoodC_web_Only_1_ZddK": "仅限1件",
  "FoodC_progress_Order_completed_joIL": "订单已完成",
  "FoodC_page_Rewards_are_dWeY": "奖励按照【被推荐人】定位所在城市发放",
  "FoodC_202412_DMKw_kWMA": "试试搜索建筑物、街道和门牌号",
  "FoodC_202408_PByw_CSDg": "解锁专属优惠",
  "FoodC_1_ScPa_MVPN": "复制成功",
  "FoodC_education_In_the_nIgD": "下列取消订单的情形下，您需承担责任：",
  "FoodC_zhaopin_Recruitment_OZrZ": "招聘",
  "FoodC_education_Platform_Delivery_MJPQ": "平台配送",
  "FoodC_202408_qGSr_IQoW": "修改",
  "FoodC_202412_SJdc_Bwta": "非附近地址？请点击右侧按钮重新获取位置。",
  "FoodC_dish_New_guest_bkGC": "新客专享",
  "FoodC_202408_WiRK_SITI": "添加地址",
  "FoodC_202408_HNlI_lTpJ": "您还没有添加小费",
  "提交": "提交",
  "soda-c-i18n---将赔付给您%s张免配送费券": "将赔付给你%s张免配送费券",
  "FoodC_all_You_haven_lSmt": "你还没有邀请你的朋友，快去邀请他们吧",
  "FoodC_dish_Look_again_fJrp": "再看看",
  "FoodC_202408_IDjR_CbbT": "确定",
  "FoodC_20240607_ffiX_wNQG": "WhatsApp",
  "soda-c-i18n---提交申请": "申请取消",
  "FoodC_rule_14_Up_dovx": "每个用户每天最多用券2张",
  "FoodC_education__2_KHhl": "（2）如您超过平台规定的时间未到达交餐点、订单地址填写错误、收餐号码错误、拒接送餐人员电话、无正当理由拒收餐品，您需要支付订单费用",
  "FoodC_nonaffiliate_Courier_is_iunG": "骑手正前往商家为您下单",
  "FoodC_edit_cYHO_lOnH": "例如：中心大道88号，中心大楼，幸福公寓",
  "FoodC_202408_IBpM_TxlD": "其他",
  "必选": "必选",
  "FoodC_education_If_you_RTIV": "您成功支付外卖价款后，如需要取消订单的，将按照如下规则进行：",
  "FoodC_education_2_Receiving_gFZl": "2. 收餐地址、联系方式填写正确；",
  "FoodC_page_The_threshold_yZFq": "优惠券使用门槛及有效期详见券面提示，未在券有效期限内使用完毕的，不可继续使用",
  "FoodC_education__3_TDsa": "（3）顾客拒收餐品",
  "FoodC_202412_QJMa_Qkwg": "输入并添加新的配送地址",
  "FoodC_education_2_If_SoSk": "2. 若骑手未按照平台规范完成交餐，导致交餐纠纷，优先骑手承担责任",
  "FoodC_202408_cjXm_VVcd": "退出后将无法查看订单",
  "FoodC_education__unk_aOCD": "{{delivery_type}}平台规则",
  "FoodC_edit_PUvz_vhsD": "小区名称",
  "确认": "确认",
  "请选择支付方式": "选择支付方式",
  "soda-c-i18n---订单实际送达时间晚于预计送达时间%s分钟以上，": "订单实际送达时间晚于预计送达时间%s分钟以上，",
  "soda-c-i18n---取消订单": "取消订单",
  "FoodC_education_3_Customers_Zoec": "3. 顾客需要保持手机畅通，便于骑手联系取餐；",
  "FoodC_202409_yzRW_xKLM": "订单已关闭。如需查看详情，请输入下单人手机号",
  "小费": "红包",
  "FoodC_update_The_expected_ZzFg": "预计送达时间以平台预估的最晚预计送达时间为准。",
  "FoodC_202412_kamX_bNUi": "请选择要添加的地址",
  "FoodC_20240607_fIqe_iMtS": "接受",
  "FoodC_202408_BTvL_umes": "确定退出吗",
  "FoodC_edit_TzbS_wBhH": "例如：中心大道88号，中心大楼，幸福公司",
  "FoodC_education__2_jwSo": "（2）放在门口（文字备注）",
  "FoodC_202408_ReqK_LVas": "确认",
  "FoodC_web_Application_Progress_UYtG": "申请进度",
  "FoodC_edit_NDmM_lEJY": "请在门口见面",
  "FoodC_202408_HfXO_xbcw": "查看",
  "FoodC_dish_Are_you_TaUN": "确认离开吗？",
  "FoodC_education__1_vwgm": "（1）送货上门",
  "FoodC_education_4_Customers_laxR": "4. 顾客需要骑手到户后10分钟内及时取餐；",
  "FoodC_edit_GeFS_ZPLW": "建筑名称",
  "FoodC_202408_cktm_HTVV": "知道了",
  "FoodC_shop__MWyO": "该商品仅可购买{{num}}份",
  "FoodC_perception_Tips_hZWk": "小费",
  "FoodC_202412_aCvV_UNyj": "添加新地址",
  "FoodC_education__1_qvVC": "（1）顾客取餐超时",
  "FoodC_method_Wait_keAM": "再等等",
  "soda-c-i18n---请告诉我们，帮助我们一起改善": "请告诉我们，帮助我们一起改善",
  "FoodC_web_Feedback_on_ookM": "网页版暂不支持相关问题的反馈，请您前往手机应用中进行操作。抱歉给您造成了不好的体验，如您仍有疑问，请联系客服帮您解决。",
  "FoodC_202408_zoCD_Pszh": "继续",
  "FoodC_supplement_Return_wVpv": "返回",
  "FoodC_2022__SEQk": "输入小费",
  "FoodC_optimization_rmLx_bSmk": "请搜索并添加您的见面地址",
  "FoodC_edit_bxpb_udFq": "例如：中心大道88号，中心大楼",
  "FoodC_202410_SiDC_Empm": "立即点餐",
  "优惠券": "优惠券",
  "FoodC_202410_OFhs_UnlA": "探索美味",
  "FoodC_progress_Order_canceled_KDSH": "订单已取消",
  "FoodC_optimization_RLDn_cLAQ": "请添加见面地址",
  "FoodC_optimization_hksX_yevn": "请添加您的见面地址，否则将无法为您正常配送",
  "FoodC_education_Meal_picking_ziun": "取餐规则",
  "soda-c-i18n---订单实际送达时间晚于预计送达时间%s分钟以上、%s分钟以下，": "订单实际送达时间晚于预计送达时间%s分钟以上、%s分钟以下，",
  "FoodC_sales_Condition_of_lnwg": "收餐时外包装密封品状态（必填）",
  "FoodC_20240607_fhRg_kmPn": "由于您当前有未完成的现金支付订单，现金支付方式暂时不可用。请在订单完成后再继续使用现金支付。",
  "FoodC_202408_XlDL_LjiM": "我知道了",
  "soda-c-i18n---送您1张%s折扣券；": "送你1张%s折扣券；",
  "productname_didi_food": "滴滴外卖",
  "FoodC_revision_Contact_customer_XbFk": "联系客服",
  "分钟": "分钟",
  "FoodC_shop__hSnf": "该商品库存仅剩{{num}}份",
  "FoodC_Website_DiDi_FoodDiDi_wtqn": "DiDi Food|滴滴外卖，随时随地享受美食！",
  "FoodC_edit_EqUG_FRvo": "公寓名/楼层/房间号",
  "FoodC_edit_dECN_fkIy": "请在写字楼外见面",
  "FoodC_202408_tJIe_WbFo": "取消",
  "FoodC_Storefront_bIgB_uLGp": "本次优惠已抢光，欢迎下次再来",
  "FoodC_202410_mjfG_DDhf": "添加",
  "FoodC_edit_XZzK_rlGs": "例如：C栋8层1118、入户代码18181#",
  "FoodC_edit_RMpJ_MPyp": "配送方式",
  "FoodC_202408_tlOV_uOmJ": "绑定手机号即可开启优惠订餐",
  "FoodC_202412_SxTA_sqKU": "附近地址",
  "FoodC_improvement_Rgol_abYw": "您已提交取消申请，商家最晚将在{{XminYs}}内处理，请您耐心等待。",
  "FoodC_dish_Purchase_NrCl": "购买",
  "FoodC_popup_After_closing_ujoW": "关闭后您将无法保存已选择的菜品和规格",
  "FoodC_edit_ldOR_SyHv": "例如：我穿着花衬衫，在街边等候。还可上传您所在位置附近的参照物图，方便骑手准确送达。",
  "FoodC_page__KWIm": "知道了",
  "FoodC_remark_Enjoy_your_OvmD": "祝您用餐愉快",
  "FoodC_optimization_knQx_VpIr": "添加",
  "soda-c-i18n---超时赔付规则说明": "超时赔付规则说明",
  "FoodC_education_5_Cash_zBuY": "5. 如下现金支付订单，顾客需准备充足的现金，现金付款后取餐；",
  "Groceries_shop2_Fwyu_yymq": "抱歉，您最多可加购{{num}}个商品",
  "FoodC_sidebar_Update_successfully_cuyI": "更新成功",
  "FoodC_education__1_IIfh": "（1）在商家接单前，您可以在订单详情页通过‘取消订单’功能进行取消",
  "支付": "支付",
  "FoodC_reasons_Enter_a_Pnzg": "请输入具体原因",
  "FoodC_202408_PvIX_VPCT": "登录后可查看专属优惠、跟踪订单状态",
  "FoodC_20240607_RSyh_XJnN": "请您用手机浏览器打开链接",
  "Groceries_1_MhVY_Etfm": "换货新商品",
  "FoodC_Notification_ZawA_BoKt": "查看全部进行中订单",
  "FoodC_edit_uMEP_ZtVV": "公司名称",
  "FoodC_20240607_IbZr_cIyA": "请检查店铺链接后重试",
  "sailing.c-api-intl.你最多可以下单{0}的货到付款单": "你最多可以下单{{0}}的货到付款单",
  "FoodC_202412_Afcx_fMwc": "选择地址",
  "FoodC_payment_eCqh_ogxh": "当前暂不支持线上支付，敬请期待！",
  "FoodC_homepage_Now_place_Kmkm": "现在下单享用送到门口的美食吧",
  "FoodC_education__4_QYXm": "（4）顾客拒绝支付现金单",
  "FoodC_202410_Ejdi_makf": "选择店铺",
  "FoodC_Storefront_Fzba_uHgH": "无法参与",
  "FoodC_education_In_the_MKeB": "下列取消订单的情形下，您无需承担责任：",
  "FoodC_education__3_ltNM": "（3）因分配不到骑手等平台原因或外部原因导致订单取消的情形",
  "FoodC_202408_Jvou_Cqor": "附近位置",
  "FoodC_202412_enwa_nWfu": "菜品已售罄，看看其他菜品吧",
  "FoodC_1_dXUH_ThDd": "查看更多",
  "soda-c-i18n---送您1张%s优惠券；": "送你1张%s优惠券；",
  "FoodC_edit_fOqC_upyr": "在大厅见面",
  "FoodC_202410_UazG_LTwS": "暂无可点餐店铺",
  "FoodC_perception_Sorry_you_FKSy": "抱歉，您最多可加购15个菜品",
  "FoodC_202410_KMCG_kJBn": "切换",
  "FoodC_20240607_YPzJ_iyMx": "您必须年满{{0}}周岁且处于清醒状态，未年人不能购买酒精类饮品",
  "FoodC_202410_hAya_NcZF": "确认({{countdown}}秒)",
  "FoodC_20240607_wWhy_deWR": "请刷新后重试",
  "FoodC_all_No_reward_ocGJ": "没有奖励",
  "FoodC_education_In_order_AZCw": "为了使您在{{brand_name}}服务中有更好的体验，平台特制订取餐规则，以供各方遵守。涉及顾客取餐纠纷的场景，将按照如下规则处理",
  "FoodC_edit_KDUB_nVRR": "必须",
  "FoodC_optimization_GUGp_fDxe": "返回上个页面",
  "FoodC_edit_oOAR_FExH": "写字楼",
  "FoodC_202412_MrwA_fRUi": "店铺休息中，稍后再来吧",
  "FoodC_education__2_KZTZ": "（2）骑手联系不上顾客",
  "FoodC_switch_TFwP_lxTd": "默认小费说明",
  "FoodB_Overview_udmx_lJTl": "加载失败，请点击重新加载。",
  "FoodC_edit_PdIk_ikix": "酒店名称",
  "FoodC_remark_Thank_you_ENoY": "感谢您使用{{brand_name}}服务",
  "FoodC_edit_NLqn_FXYs": "我没有这个信息",
  "soda-c-i18n---联系商家": "联系商家",
  "FoodC_dish_Leaving_LwWJ": "离开",
  "FoodC_202408_ajMC_lsta": "店铺已打烊",
  "FoodC_20240607_qihL_obWb": "店铺不存在",
  "FoodC_education_Cancellation_Policy_fxrl": "取消规则",
  "FoodC_202409_rOOL_Peyp": "输入手机号",
  "FoodC_edit_wBJW_yfSo": "公寓",
  "FoodC_202408_RTrU_hOhN": "请先开启位置访问授权。系统设置->找到应用程序->开启位置访问授权",
  "FoodC_202408_IfNx_VyNu": "已保存地址",
  "FoodC_dish_Current_activity_Iyrv": "当前活动将在{{arrived_time}}后结束，结束后您将无法购买新人专享特价菜品",
  "FoodC_dish_You_will_AXAT": "离开您将无法购买新人专享特价菜品",
  "FoodC_edit_NwrE_OHgM": "请在公寓楼外见面",
  "FoodC_20240607_aOOB_RMKm": "暂不支持现金",
  "soda-c-i18n---晚间：%s-%s": "晚间：%s-%s",
  "FoodC_edit_TfNW_USPN": "例如：8层1118号房间",
  "FoodC_edit_cKhP_xcTQ": "请在我家门口见面",
  "FoodC_20240607_hfCf_kdpO": "电话",
  "FoodC_page__iyoe": "收起",
  "FoodC_202410_YFVb_hzJK": "没有地址，点击添加",
  "FoodC_202408_YjqN_Yvyz": "添加地址后，可查看配送费和配送时长",
  "FoodC_202410_FxEp_LCxL": "支付中",
  "Groceries_web__Ovnh": "抱歉，商超店铺目前只支持在app端查看",
  "FoodC_edit_DOXu_EYnd": "例如：请敲门，不要按门铃。还可上传您所在位置附近的参照物图，方便骑手准确送达。",
  "soda-c-i18n---将赔付给您1张%s折扣券": "将赔付给你1张%s折扣券",
  "FoodC_education_1_If_iWDm": "1. 如顾客未按照上述要求取餐，导致交餐纠纷，优先顾客承担责任，具体场景包括且不限于：",
  "soda-c-i18n---送您%s张免配送费券；": "送你%s张免配送费券；",
  "FoodB_supplement_Network_connection_RVcl": "网络连接失败，请检查网络设置",
  "FoodC_202408_IMjl_ywFc": "重新定位",
  "FoodC_page_Rewards_will_NhMd": "奖励会在邀请成功后的48小时内发放入您的账号，您可在{{\t\nproductname_didi_food}}-钱包-券中查看",
  "FoodC_202410_pHYX_VjbD": "关闭"
}