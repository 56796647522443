/**
 * 组件引入main配置
 */

import Vue from 'vue'
import Popup from '@/components/Popup/index'
import '@/components/Popup/style.css'

import { Swiper as SwiperClass, Pagination, Navigation, Autoplay } from 'swiper/js/swiper.esm'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
import 'swiper/css/swiper.css'

import DishFood from '@/components/DishFood/index.js'
import DishFoodV2 from '@/components/DishFoodV2/index.js'

import RLoading from '@/components/Loading/index.js'
import CardPopup from '@/components/Pay/cardPopup.js'
import CodePopup from '@/components/Coupon/codePopup.js'
Vue.component(Popup.name, Popup)
// Swiper modules
SwiperClass.use([Pagination, Navigation, Autoplay])
// Global use Swiper
Vue.use(getAwesomeSwiper(SwiperClass))

Vue.prototype.$dishFood = DishFood
Vue.prototype.$dishFoodV2 = DishFoodV2
Vue.prototype.$rLoading = RLoading
Vue.prototype.$cardPopup = CardPopup
Vue.prototype.$codePopup = CodePopup
