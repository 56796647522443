<template>
  <div
    class="cate-header-wrapper"
    :class="{ 'trans-open': transOpen, 'trans-show': transShow, 'pos-sticky': posSticky }"
  >
    <h1 class="title" v-text="title"></h1>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'CateHeader',
  components: {},
  props: {
    // eslint-disable-next-line vue/require-default-prop
    title: String,
    posSticky: Boolean,
    transShow: Boolean,
    transOpen: Boolean,
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {},
}
</script>

<style lang="scss" scoped>
.cate-header-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 6;
  width: 100%;
  background: #ffffff;
  border-radius: 8px 8px 0px 0px;
}
.pos-sticky {
  position: -webkit-sticky;
  position: sticky;
}
.cate-header-wrapper.trans-open {
  transform: translateY(-100%);
  transition: all 0.1s ease;
}
.cate-header-wrapper.trans-show {
  transform: translateY(0);
  transition-duration: 0.3s;
}
.title {
  padding: 20px 66px;
  height: 72px;
  line-height: 32px;
  text-align: center;
  font-family: 'Aspira Medium';
  font-weight: 500;
  font-size: 24px;
  color: #000000;
  word-break: break-all;
  word-wrap: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  .cate-header-wrapper {
    border-radius: rem(24) rem(24) 0px 0px;
  }
  .title {
    padding: rem(40);
    height: rem(130);
    line-height: rem(64);
    font-size: rem(48);
  }
}
</style>
