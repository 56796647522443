module.exports = {
  "已选择": "Selected",
  "FoodC_edit_stZr_IBeA": "Other",
  "FoodC_check_HTib_sWqA": "Ordering",
  "FoodC_202409_dTTH_bjFQ": "This order has been completed or does not exist, and cannot be viewed",
  "FoodC_edit_AxtX_PAiY": "Example: 1118, Building C",
  "FoodC_202412_PwIG_QPFY": "Enter an Address",
  "FoodB_Overview_AasD_LvfW": "View More",
  "FoodC_edit_miEX_KTUj": "Floor / Room No.",
  "CS_page_Courier_Phone_ghUr": "Courier Phone No.",
  "FoodC_Website_At_present_XpFw": "Unable to redeem on web at this time. Please redeem promo code in the app",
  "FoodC_20240607_eLRx_hJRj": "Page Unavailable on Desktop",
  "FoodC_edit_rpfC_Jyhp": "Building / Floor / Unit / Room No.",
  "FoodC_202408_wten_GUVt": "Select Delivery Address",
  "FoodC_edit_Hxmm_vrkU": "Residential Building",
  "FoodC_202408_PJcq_SjqG": "Oops! No contact information is available for this store",
  "FoodC_001_The_WEB_Iizt": "Web version currently does not support PayPay payments. To use PayPay, please download {{download}}",
  "FoodC_page_Upon_discovery_baTq": "Coupon may not be exchanged or redeemed for cash. A violation of the coupon rules may result in the recovery of the relevant amount and account suspension, and may be punishable by law",
  "FoodC_homepage_Enter_delivery_IZAu": "Enter delivery address",
  "FoodC_edit_bZFb_ghVG": "Meet outside hotel building",
  "FoodC_edit_qhyi_ihvA": "Example: Fortune Hotel, Central Building, No. 88 Central Avenue",
  "FoodC_Card2_When_the_sFOa": "Open at",
  "soda-c-i18n---早间：%s-%s": "Mornings: %s-%s",
  "FoodB_202303_YWUL_hwNF": "OK",
  "FoodC_Storefront_iOFy_aaef": "This store is not participating in this promotion",
  "soda-c-i18n---延迟送达%s分钟以上": "%s+ minute delay",
  "FoodC_stores_All_stores_clRv": "All Stores",
  "FoodC_edit_SQwr_AVSp": "Apartment Name",
  "FoodC_edit_OVRs_CzUt": "Hotel",
  "FoodC_dish_XX_YY_opCV": "Ends in",
  "FoodC_notice_fOyQ_lqIn": "Cash payment is unavailable. Please check whether the order amount exceeds your limit, or if you have another cash order in progress, then try again later.",
  "FoodC_edit_SKZZ_WpVu": "Building Type",
  "FoodC_202408_HkLu_Mwgv": "No Location Permissions",
  "soda-c-i18n---个人中心的“优惠券”": " Account > Coupons.",
  "FoodC_202408_QAer_OYaB": "Welcome!",
  "FoodC_202408_leda_oOpl": "Track orders better",
  "soda-c-i18n---1、超时赔付服务是%s为您免费提供的服务，当前订单（不含已取消订单）未在预计送达时间内送达，%s将赔付给您一定金额的优惠券，订单完成后可以在中查看。": "1. Compensating you for a late delivery is our way of making things right. If your order isn’t delivered by the estimated delivery time (excluding canceled orders), %s will give you one or more free coupons. You can view your coupons after your order is completed by going to ",
  "FoodC_homepage_Search_for_CiMZ": "Find Food",
  "FoodC_edit_SkdG_WQKu": "Meet in hotel lobby",
  "FoodC_20240607_wfRH_rTAa": "Store Info",
  "FoodD_suspensionpage_In_the_zGEU": "No more results",
  "FoodC_202408_AFKG_hWIj": "Outside delivery area. Select another address",
  "FoodB_reason_Contact_Courier_Fqog": "Contact Courier",
  "FoodC_202412_nXPk_kwtQ": "Address not found? Try different keywords",
  "FoodC_edit_QrTY_mpUt": "Example: Fortune Neighborhood, Central Building, No. 88 Central Avenue",
  "Entrega_order__YnnH": "DiDi Entrega Business",
  "FoodC_education_1_Customers_Gpbb": "1. Before placing an order, you will be asked to choose your preferred delivery option. The courier will then deliver your order to you using your selected option:",
  "FoodC_perception_Tip_exceeds_VSFf": "Tip amount exceeds limit",
  "FoodC_202408_reKH_lclj": "Add delivery address",
  "FoodC_edit_hDGv_arOT": "Meet in downstairs lobby",
  "soda-c-i18n---现金": "Cash",
  "sailing.d-api.common.页面获取失败，请稍后重试": "Unable to retrieve page. Try again later",
  "FoodC_education__1_mJTG": "(1) If the store has already confirmed and accepted the order, you can use the ‘Cancel Order’ feature to contact the store and ask to cancel the order. If the store does not agree to cancel the order because they have already finished preparing it, or other reasonable cause, you will not be able to cancel the order for free. If you insist on cancelling or refuse to accept all or part of the order, you will be responsible for paying all relevant order fees.",
  "FoodC_20240607_STdK_ZTMu": "Technical service provided by {{brand_name}}",
  "FoodC_education_Principles_of_RKEk": "Rules for Handling Delivery Disputes",
  "soda-c-i18n---当前订单（不含已取消订单）未在预计送达时间内送达，%s将赔付给您一定金额的优惠券，订单完成后可以在": "If an order isn’t delivered by the estimated delivery time (excluding canceled orders), %s will give you one or more coupons to help make things right. After your order is completed, you can view your coupons by going to ",
  "soda-c-i18n---将赔付给您1张%s优惠券": "You get a %s off coupon",
  "FoodC_edit_iuIV_BOva": "10 minutes",
  "FoodC_20240607_Olbe_DAqS": "Unable to Load",
  "FoodC_202408_SXSA_LasS": "Add Mobile Number to Enjoy More Perks",
  "FoodC_page_Invite_friends_oGuj": "Invite a friend to try {{\t\nproductname_didi_food}}. If they claim the free coupon you send and complete an order without any refunds within 14 days of receiving your invitation and before the event ends, even if they don’t use the free coupon on their order, you’ll receive a {{\t\nproductname_didi_food}} [coupon]",
  "FoodC_202408_GeGr_xwdt": "Communicate more easily",
  "提交申请": "Submit Request",
  "支付失败": "Payment failed",
  "FoodC_202408_xwYJ_IzdZ": "Continue",
  "FoodC_referral_Copy_link_kCti": "Copy",
  "FoodC_202408_ksyj_sXlp": "About the Store",
  "FoodC_sidebar_Scan_QR_ONzJ": "Please scan the QR code using your phone camera",
  "FoodC_sidebar_Use_the_huoZ": "Get the app to order anytime, anywhere",
  "取消": "Cancel",
  "FoodC_education_3_If_wduh": "3. If a delivery dispute is caused by reasons other than human factors, such as a product issue or bad weather, we’ll take responsibility",
  "FoodC_web_Customer_Service_EBqf": "Customer Service",
  "FoodC_MVP_ASpO_MFfF": "Order Details",
  "FoodC_202412_dCuL_VUFU": "Refresh",
  "FoodC_edit_GoWo_Frbk": "Floor / Unit / Room No.",
  "FoodC_details_Refund_Details_sGIk": "Refund details",
  "FoodC_202412_bolI_YXBT": "Saved Addresses",
  "FoodC_page_This_special_fYuo": "This promo is only offered in areas of {{\t\nactivity_city}} where {{\t\nproductname_didi_food}} is available",
  "FoodC_page_For_any_IWNZ": "For any questions about this promo, please contact Customer Service at {{contact_email}}",
  "Entrega_name_99_Flash_maAd": "99Entrega",
  "FoodC_20240607_Upph_ldoj": "Purchase Reminder",
  "支付方式": "Payment Method",
  "FoodC_education_Basic_requirements_hIbv": "Basic Delivery Rules for Customers",
  "FoodC_202410_ZHQi_kNsP": "Edit",
  "FoodC_202412_Oant_EESB": "Next",
  "FoodC_edit_JdIz_uKvw": "Meet outside",
  "FoodC_education_In_order_SRIb": "This Cancellation Policy was formulated to help all users enjoy a fair and reliable experience when using {{brand_name}}.",
  "FoodC_1_mnqk_AcOb": "Unable to copy",
  "FoodC_202408_qXNe_iBNs": "Unable to get current location",
  "FoodC_202408_LBac_RLgP": "Please add your delivery address so the courier can deliver the order to you",
  "FoodC_202408_sbmp_YFyl": "Current Location",
  "FoodC_202408_RRtT_ZjIG": "Add Delivery Address",
  "FoodC_20240607_hhNB_mCBD": "Mobile Number",
  "FoodC_presentation_DiDi_Delivery_vrwd": "DiDi Entrega",
  "更多": "More",
  "FoodC_202408_ZOBi_xjfW": "Search for a place like Building/Apt.",
  "FoodC_supplement_Modify_Mobile_ZJkT": "To modify your phone number or password, add an email address, or delete your account, please",
  "FoodC_address_gNYi_HdzX": "Unable to Save",
  "FoodC_edit_fLUx_wHAP": "Example: I’m wearing a patterned shirt. You can also upload pictures of your surroundings to help the courier deliver your order to the correct place.",
  "FoodD_problem_I_see_NhGG": "OK",
  "FoodC_202408_BsWi_bWTi": "Edit Address",
  "FoodC_202408_LtuD_oTxz": "Cancel",
  "FoodC_sales_If_the_TsPm": "To help us verify the issue with the store and courier, please let us know if the item was delivered in resealable packaging, or sealed in another way (like with staples).",
  "FoodOpC_backend_Copy_HxOc": "Copy",
  "FoodC_202408_uMeD_ECQU": "Something went wrong. Please try again",
  "FoodC_202408_KYqe_yVZD": "Not Now",
  "FoodC_edit_kzdk_yzVc": "Meet at door to my room",
  "FoodC_Website_The_WEB_pBbW": "The web version does not support payment with {{pay_name}}. To pay with {{pay_name}}, please download our app",
  "FoodC_Website_From_shop_Jatv": "Place an order from [shop name] | DiDi Food",
  "帮助中心": "Help Center",
  "FoodC_edit_Fyzp_tERt": "The courier will contact you when they arrive and wait at the agreed location for {{time}}. Please be ready to answer your phone",
  "FoodC_202408_pSzr_CJqz": "Save Address",
  "FoodC_sales_Supplementary_information_ihyk": "Additional Details",
  "FoodC_edit_IhsK_uKOF": "Please select",
  "FoodC_education__2_uVUK": "(2) The store you ordered from is closed, an item is sold out, an accident occurs, or the order is cancelled by the store or courier for another reason, in which case you will receive a full refund.",
  "FoodC_001_This_operation_Dwog": "This will delete all items in your cart. Continue?",
  "FoodC_202412_JiED_bPrx": "No results. Enter a valid building, street & house number",
  "FoodC_page_Your_invited_sPpR": "The friend you invite must be a new {{\t\nproductname_didi_food}} customer. First-time order discounts are limited to one per customer (each customer must have a unique phone number, device, and payment account)",
  "FoodC_edit_HEFc_Dppo": "Meet outside my residential building",
  "FoodC_web_Only_1_ZddK": "Limit 1 item",
  "FoodC_progress_Order_completed_joIL": "Order completed",
  "FoodC_page_Rewards_are_dWeY": "Reward is issued based on the city in which the [person referred] is located",
  "FoodC_202412_DMKw_kWMA": "Try searching by building, street & house number",
  "FoodC_202408_PByw_CSDg": "Unlock exclusive offers",
  "FoodC_1_ScPa_MVPN": "Copied",
  "FoodC_education_In_the_nIgD": "You will be responsible for a cancellation in the following situations:",
  "FoodC_zhaopin_Recruitment_OZrZ": "Join Us",
  "FoodC_education_Platform_Delivery_MJPQ": "Platform Delivery",
  "FoodC_202408_qGSr_IQoW": "Edit",
  "FoodC_202412_SJdc_Bwta": "Not a nearby location? Tap the button on the right to refresh your location.",
  "FoodC_dish_New_guest_bkGC": "New Users Only",
  "FoodC_202408_WiRK_SITI": "Add Address",
  "FoodC_202408_HNlI_lTpJ": "You haven’t added a tip yet",
  "提交": "Submit",
  "soda-c-i18n---将赔付给您%s张免配送费券": "You get %s Free Delivery coupon",
  "FoodC_all_You_haven_lSmt": "You haven’t invited any friends yet. Try inviting someone",
  "FoodC_dish_Look_again_fJrp": "Not Now",
  "FoodC_202408_IDjR_CbbT": "OK",
  "FoodC_20240607_ffiX_wNQG": "WhatsApp",
  "soda-c-i18n---提交申请": "Request Cancellation",
  "FoodC_rule_14_Up_dovx": "Limit 2 coupons per customer per day",
  "FoodC_education__2_KHhl": "(2) If you do not arrive at the delivery location by the specified time, or if you enter the wrong delivery address or phone number, refuse to answer a call from the courier, or refuse delivery in whole or in part without a valid reason, you will be required to pay for the order.",
  "FoodC_nonaffiliate_Courier_is_iunG": "The courier is heading to the store to place your order",
  "FoodC_edit_cYHO_lOnH": "Example: Fortune Apartment, Central Building, No. 88 Central Avenue",
  "FoodC_202408_IBpM_TxlD": "Other",
  "必选": "Cannot disable",
  "FoodC_education_If_you_RTIV": "If you paid for an order and need to cancel it, the following rules will apply:",
  "FoodC_education_2_Receiving_gFZl": "2. You should ensure that the delivery address and contact method you provide are correct.",
  "FoodC_page_The_threshold_yZFq": "Coupon usage limits and validity period are displayed on the coupon. Coupons may only be used during the validity period",
  "FoodC_education__3_TDsa": "(3) You refuse to accept the order in whole or in part",
  "FoodC_202412_QJMa_Qkwg": "Enter a new delivery address to add",
  "FoodC_education_2_If_SoSk": "2. If a delivery dispute occurs because the courier did not follow our rules, we may hold the courier responsible",
  "FoodC_202408_cjXm_VVcd": "After exiting, you won’t be able to view your order",
  "FoodC_education__unk_aOCD": "{{delivery_type}} App Rules",
  "FoodC_edit_PUvz_vhsD": "Neighborhood Name",
  "确认": "OK",
  "请选择支付方式": "Select Payment Method",
  "soda-c-i18n---订单实际送达时间晚于预计送达时间%s分钟以上，": "If your order is delivered over %s minutes after the estimated delivery time,",
  "soda-c-i18n---取消订单": "Cancel Order",
  "FoodC_education_3_Customers_Zoec": "3. You should have access to your phone during delivery to ensure the courier can get in touch with you if needed.",
  "FoodC_202409_yzRW_xKLM": "This order is closed. To view details, please enter the mobile number of the requester",
  "小费": "Tip",
  "FoodC_update_The_expected_ZzFg": "The estimated delivery time is based on the latest estimated arrival time.",
  "FoodC_202412_kamX_bNUi": "Please select an address to add",
  "FoodC_20240607_fIqe_iMtS": "Accept",
  "FoodC_202408_BTvL_umes": "Are you sure you want to exit?",
  "FoodC_edit_TzbS_wBhH": "Example: Fortune Company, Central Building, No. 88 Central Avenue",
  "FoodC_education__2_jwSo": "(2) Leave at door (with note)",
  "FoodC_202408_ReqK_LVas": "Confirm",
  "FoodC_web_Application_Progress_UYtG": "Request Status",
  "FoodC_edit_NDmM_lEJY": "Meet at door",
  "FoodC_202408_HfXO_xbcw": "View",
  "FoodC_dish_Are_you_TaUN": "Leave Promo Page?",
  "FoodC_education__1_vwgm": "(1) Deliver to door",
  "FoodC_education_4_Customers_laxR": "4. You should accept your order within 10 minutes of the courier’s arrival at the delivery location.",
  "FoodC_edit_GeFS_ZPLW": "Building Name",
  "FoodC_202408_cktm_HTVV": "Got It",
  "FoodC_shop__MWyO": "Purchase limit: {{num}}",
  "FoodC_perception_Tips_hZWk": "Tip",
  "FoodC_202412_aCvV_UNyj": "Add New Address",
  "FoodC_education__1_qvVC": "(1) You don’t pick up your order in time",
  "FoodC_method_Wait_keAM": "Wait",
  "soda-c-i18n---请告诉我们，帮助我们一起改善": "Let us know and help us improve",
  "FoodC_web_Feedback_on_ookM": "Sorry, feedback cannot be submitted using the web version at this time. To submit feedback, please use the mobile app. If you have any questions, please contact Customer Service.",
  "FoodC_202408_zoCD_Pszh": "Continue",
  "FoodC_supplement_Return_wVpv": "Back",
  "FoodC_2022__SEQk": "Enter Tip Amount",
  "FoodC_optimization_rmLx_bSmk": "Please search for your meeting address to add it",
  "FoodC_edit_bxpb_udFq": "Example: Central Building, No. 88 Central Avenue",
  "FoodC_202410_SiDC_Empm": "Order Now",
  "优惠券": "Voucher",
  "FoodC_202410_OFhs_UnlA": "Explore",
  "FoodC_progress_Order_canceled_KDSH": "Order cancelled",
  "FoodC_optimization_RLDn_cLAQ": "Please Add Your Meeting Address",
  "FoodC_optimization_hksX_yevn": "Please add your meeting address so we can deliver your order to you",
  "FoodC_education_Meal_picking_ziun": "Delivery Rules",
  "soda-c-i18n---订单实际送达时间晚于预计送达时间%s分钟以上、%s分钟以下，": " If your order is delivered %s–%s minutes after the estimated delivery time, ",
  "FoodC_sales_Condition_of_lnwg": "Was the item packaging sealed? (Required)",
  "FoodC_20240607_fhRg_kmPn": "You have an uncompleted cash order, and cannot use cash payment. Please try again when that order is completed.",
  "FoodC_202408_XlDL_LjiM": "Got It",
  "soda-c-i18n---送您1张%s折扣券；": "we’ll give you a %s off coupon;",
  "productname_didi_food": "DiDi Food",
  "FoodC_revision_Contact_customer_XbFk": "Contact Us",
  "分钟": " min",
  "FoodC_shop__hSnf": "Only {{num}} left",
  "FoodC_Website_DiDi_FoodDiDi_wtqn": "DiDi Food | DiDi Delivery. Enjoy Great Food Anytime, Anywhere",
  "FoodC_edit_EqUG_FRvo": "Apartment Name / Floor / Room No.",
  "FoodC_edit_dECN_fkIy": "Meet outside office building",
  "FoodC_202408_tJIe_WbFo": "Cancel",
  "FoodC_Storefront_bIgB_uLGp": "All discounts have been claimed. Please try again next time",
  "FoodC_202410_mjfG_DDhf": "Add",
  "FoodC_edit_XZzK_rlGs": "Example: 1118, Floor 8, Building C; Entry code: 18181#",
  "FoodC_edit_RMpJ_MPyp": "Delivery Method",
  "FoodC_202408_tlOV_uOmJ": "Add your mobile number to enjoy order discounts",
  "FoodC_202412_SxTA_sqKU": "Nearby Location",
  "FoodC_improvement_Rgol_abYw": "Your cancellation request was submitted and will be processed by the store within {{XminYs}}. Thank you for your patience.",
  "FoodC_dish_Purchase_NrCl": "Order",
  "FoodC_popup_After_closing_ujoW": "If you leave this page, your item selections won’t be saved",
  "FoodC_edit_ldOR_SyHv": "Example: I’m wearing a patterned shirt and waiting on the street. You can also upload pictures of your surroundings to help the courier deliver your order to the correct place.",
  "FoodC_page__KWIm": "Got It",
  "FoodC_remark_Enjoy_your_OvmD": "We hope you enjoy your meal",
  "FoodC_optimization_knQx_VpIr": "Add",
  "soda-c-i18n---超时赔付规则说明": "Coupon for Late Delivery",
  "FoodC_education_5_Cash_zBuY": "5. If you place a cash order, ensure you have enough cash on hand to pay for the order before accepting delivery.",
  "Groceries_shop2_Fwyu_yymq": "Sorry, you can only add up to {{num}} items to your cart",
  "FoodC_sidebar_Update_successfully_cuyI": "Updated successfully",
  "FoodC_education__1_IIfh": "(1) You cancel before the store has accepted the order by using the ‘Cancel Order’ feature on the Order Details page.",
  "支付": "Pay",
  "FoodC_reasons_Enter_a_Pnzg": "Please describe the issue in detail",
  "FoodC_202408_PvIX_VPCT": "Sign in to view offers and track order status",
  "FoodC_20240607_RSyh_XJnN": "Please open the link on your mobile device",
  "Groceries_1_MhVY_Etfm": "Item Changed",
  "FoodC_Notification_ZawA_BoKt": "View all ongoing orders",
  "FoodC_edit_uMEP_ZtVV": "Company Name",
  "FoodC_20240607_IbZr_cIyA": "Please check the store link and try again",
  "sailing.c-api-intl.你最多可以下单{0}的货到付款单": "Pay on Delivery is available for orders up to {{0}}",
  "FoodC_202412_Afcx_fMwc": "Select Address",
  "FoodC_payment_eCqh_ogxh": "Online payment is not available yet. Stay tuned!",
  "FoodC_homepage_Now_place_Kmkm": "Get food delivered straight to your door",
  "FoodC_education__4_QYXm": "(4) You refuse to pay for a cash order",
  "FoodC_202410_Ejdi_makf": "Select a Store",
  "FoodC_Storefront_Fzba_uHgH": "Unable to Join",
  "FoodC_education_In_the_MKeB": "You will not be responsible for a cancellation in the following situations:",
  "FoodC_education__3_ltNM": "(3) Other factors outside of your control that cause the order to be cancelled, such as if we can’t find a courier to deliver the order, or some other issue on our end.",
  "FoodC_202408_Jvou_Cqor": "Nearby Places",
  "FoodC_202412_enwa_nWfu": "This item is sold out. Try exploring other items in the store",
  "FoodC_1_dXUH_ThDd": "See more",
  "soda-c-i18n---送您1张%s优惠券；": "we’ll give you a %s off coupon;",
  "FoodC_edit_fOqC_upyr": "Meet in lobby",
  "FoodC_202410_UazG_LTwS": "No stores available",
  "FoodC_perception_Sorry_you_FKSy": "Sorry, you can only add up to 15 items to your cart",
  "FoodC_202410_KMCG_kJBn": "Change",
  "FoodC_20240607_YPzJ_iyMx": "You must be at least {{0}} years old and not intoxicated. Alcohol cannot be purchased by minors",
  "FoodC_202410_hAya_NcZF": "Confirm ({{countdown}}s)",
  "FoodC_20240607_wWhy_deWR": "Please refresh the page and try again",
  "FoodC_all_No_reward_ocGJ": "No Rewards",
  "FoodC_education_In_order_AZCw": "These Delivery Rules were formulated to help all users enjoy a fair and reliable experience when using {{brand_name}}. In the event of a delivery related dispute, the following rules will apply",
  "FoodC_edit_KDUB_nVRR": "Required",
  "FoodC_optimization_GUGp_fDxe": "Back to Previous Page",
  "FoodC_edit_oOAR_FExH": "Office Building",
  "FoodC_202412_MrwA_fRUi": "This store is currently closed. Please visit again later",
  "FoodC_education__2_KZTZ": "(2) The courier is unable to contact you",
  "FoodC_switch_TFwP_lxTd": "Default Tip Option",
  "FoodB_Overview_udmx_lJTl": "Error loading. Tap to retry.",
  "FoodC_edit_PdIk_ikix": "Hotel Name",
  "FoodC_remark_Thank_you_ENoY": "Thanks for choosing {{brand_name}}",
  "FoodC_edit_NLqn_FXYs": "I don’t have this info",
  "soda-c-i18n---联系商家": "Contact Store",
  "FoodC_dish_Leaving_LwWJ": "Leave",
  "FoodC_202408_ajMC_lsta": "Store Closed",
  "FoodC_20240607_qihL_obWb": "Store Not Found",
  "FoodC_education_Cancellation_Policy_fxrl": "Cancellation Policy",
  "FoodC_202409_rOOL_Peyp": "Enter Mobile Number",
  "FoodC_edit_wBJW_yfSo": "Apartment",
  "FoodC_202408_RTrU_hOhN": "Please enable location permissions. To do this, go to System Settings, select the app, then enable location permissions for the app",
  "FoodC_202408_IfNx_VyNu": "Saved Addresses",
  "FoodC_dish_Current_activity_Iyrv": "This promo ends in {{arrived_time}}. After that, new user deals will be unavailable",
  "FoodC_dish_You_will_AXAT": "You can only enjoy the New User Deals on this page.",
  "FoodC_edit_NwrE_OHgM": "Meet outside apartment building",
  "FoodC_20240607_aOOB_RMKm": "Cash payment is temporarily unavailable",
  "soda-c-i18n---晚间：%s-%s": "Evenings: %s-%s",
  "FoodC_edit_TfNW_USPN": "Example: Room No. 1118, Floor 8",
  "FoodC_edit_cKhP_xcTQ": "Meet at door to my home",
  "FoodC_20240607_hfCf_kdpO": "Phone",
  "FoodC_page__iyoe": "Show Less",
  "FoodC_202410_YFVb_hzJK": "No address. Add now",
  "FoodC_202408_YjqN_Yvyz": "Add address to view delivery fee and time",
  "FoodC_202410_FxEp_LCxL": "Paying",
  "Groceries_web__Ovnh": "Sorry! Grocery stores are only visible in the mobile app at this time",
  "FoodC_edit_DOXu_EYnd": "Example: Please knock the door, don’t ring the doorbell. You can also upload pictures of your surroundings to help the courier deliver your order to the correct place.",
  "soda-c-i18n---将赔付给您1张%s折扣券": "You get a %s off coupon",
  "FoodC_education_1_If_iWDm": "1. If you’re unable to meet the above requirements for accepting delivery, you may be held responsible in the event of a delivery dispute. This includes, but is not limited to, situations where:",
  "soda-c-i18n---送您%s张免配送费券；": "we’ll give you %s Free Delivery coupon",
  "FoodB_supplement_Network_connection_RVcl": "Connection failed. Please check your network settings",
  "FoodC_202408_IMjl_ywFc": "Update",
  "FoodC_page_Rewards_will_NhMd": "Your reward will be added to your account within 48 hours of a successful referral. To view rewards, go to {{\t\nproductname_didi_food}} > Wallet > Coupons",
  "FoodC_202410_pHYX_VjbD": "Close"
}