<template>
  <div class="stepper-wrapper" :class="{ disabled: disabled }">
    <div
      v-show="copies > min"
      class="minus-sign"
      :class="{ action: copies > min }"
      @click="reduceCopies($event)"
    >
      <i class="icon-outlined_subtract"></i>
    </div>
    <div v-show="copies > min" class="copies-num" v-text="copies"></div>
    <div class="plus-sign" :class="{ action: copies < max }" @click="addCopies($event)">
      <i class="icon-outlined_add"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Stepper',
  props: {
    // eslint-disable-next-line vue/require-default-prop
    copies: Number,
    disabled: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: Infinity,
    },
  },
  data() {
    return {}
  },
  methods: {
    reduceCopies(e) {
      e.preventDefault()
      e.stopPropagation()
      this.$emit('remove')
    },
    addCopies(e) {
      if (this.disabled) return
      e.preventDefault()
      e.stopPropagation()
      this.$emit('add')
    },
  },
}
</script>

<style lang="scss" scoped>
.stepper-wrapper {
  display: flex;
  height: 20px;
  text-align: center;
  background: #ffffff;
}
.minus-sign,
.plus-sign {
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 20px;
  color: #cccccc;
  cursor: pointer;
  user-select: none;
  border-radius: 50%;
}
.minus-sign.action,
.plus-sign.action {
  // @include font-color();
  // @include border-color();
  color: var(--color-6);
  border-color: var(--color-6);
}
.copies-num {
  flex: 1;
  min-width: 34px;
  height: 20px;
  line-height: 20px;
  font-family: 'Aspira Medium';
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}

.stepper-wrapper.disabled {
  // .copies-num {
  //   color: #999;
  // }
  .plus-sign {
    color: #cccccc !important;
  }
}
@media screen and (max-width: 768px) {
  .stepper-wrapper {
    height: 20px;
  }
  .minus-sign,
  .plus-sign {
    width: rem(40);
    height: rem(40);
    line-height: rem(40);
    font-size: rem(40);
  }
  .copies-num {
    min-width: rem(68);
    height: rem(40);
    line-height: rem(40);
    font-size: rem(36);
  }
}
</style>
