<template>
  <div v-if="data.length > 0" class="tag-list">
    <div v-for="(tagItem, tagIndex) in data" :key="tagIndex" :class="getTagItemClass(tagItem)">
      <template v-if="tagItem.contentArray">
        <div
          v-for="(contentItem, contentArrIndex) in tagItem.contentArray"
          :key="contentArrIndex"
          :class="getContentItemClass(tagItem)"
        >
          <RichText :text="contentItem" />
        </div>
      </template>
      <template v-else>
        <div :class="getContentItemClass(tagItem)">
          <RichText :text="tagItem.content" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import RichText from '@/components/RichText'
export default {
  components: {
    RichText,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    getTagItemClass(tagItem) {
      return tagItem.style === 1 ? 'act-tag-item' : ''
    },
    getContentItemClass(tagItem) {
      return tagItem.style === 1 ? 'add-background' : 'add-margin'
    },
  },
}
</script>

<style lang="scss" scoped>
.tag-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-right: rem(20);
  .add-margin {
    margin-left: rem(8);
    ::v-deep span {
      display: inherit;
      font-family: DiDiSans-Pro-Regular !important;
      font-size: rem(24) !important;
      font-weight: 400 !important;
    }
  }
  .add-background {
    display: flex;
    ::v-deep span {
      color: #ffffff !important;
      display: inherit;
      font-family: DiDiSans-Pro-Medium !important;
      font-size: rem(24) !important;
      letter-spacing: 0 !important;
    }
  }
  .act-tag-item {
    display: flex;
    // background: #ffe5d9;
    background-color: var(--color-6);
    border-radius: rem(24) rem(6);
    padding: rem(2) rem(20);
    line-height: rem(32);
    margin: rem(8) 0;
    .add-background:not(:last-child)::after {
      content: '';
      display: inline-block;
      width: rem(2);
      height: rem(16);
      background: #ffffff;
      margin: rem(8) rem(12);
      vertical-align: middle;
    }
  }
  .act-tag-item:not(:last-child) {
    margin-right: rem(8);
  }
}
</style>
