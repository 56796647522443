<template>
  <div v-if="content" class="msg-wrapper">
    <p class="msg-desc">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        overflow="hidden"
        viewBox="0 0 48 48"
        style="width: 1em; height: 1em; vertical-align: middle"
      >
        <path
          fill-rule="nonzero"
          d="M17 45a2 2 0 1 1 0-4h5v-5.104c-9.553-1-17-9.078-17-18.896v-5a2 2 0 0 1 2-2h20.676l1.396-5.072a2 2 0 0 1 1.77-1.464L31 3.458h9a2 2 0 0 1 .15 3.995l-.15.005h-7.476l-.699 2.541L41 10a2 2 0 0 1 1.995 1.85L43 12v5c0 9.818-7.446 17.896-17 18.896v5.103L31 41a2 2 0 1 1 0 4H17zm20.752-22H10.248c2.275 5.207 7.421 8.873 13.438 8.997L24 32c6.15 0 11.437-3.702 13.752-9zm-11.176-9H9v3c0 .742.054 1.472.158 2.185C9.415 19.066 9.7 19 10 19h15.2l1.376-5zM39 14h-8.276l-1.375 5H38c.3 0 .585.066.841.185A15.01 15.01 0 0 0 39 17v-3z"
        />
      </svg>
      <span>{{ content }}</span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'WeakTips',
  props: {
    // eslint-disable-next-line vue/require-default-prop
    content: String,
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {},
}
</script>

<style lang="scss" scoped>
.msg-desc {
  padding: 12px 14px;
  line-height: 16px;
  font-size: 12px;
  font-weight: 200;
  background: #ffe5d9;
  font-family: DiDiSans-Pro-Regular;
  color: #0b0b0d;
  border-radius: 32px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  svg {
    vertical-align: middle;
    margin-right: 4px;
    font-size: 14px;
  }
  span {
    flex: 1;
  }
}
@media screen and (max-width: 768px) {
  .msg-desc {
    padding: rem(20) rem(32);
    line-height: rem(40);
    font-size: rem(32);
    border-radius: rem(32);
    background-color: var(--color-1);
    svg {
      font-size: rem(32);
      padding-right: rem(8);
      position: relative;
      top: rem(-2);
    }
  }
}
</style>
